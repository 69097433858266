// Packages
import NumberFormat from 'react-number-format';
import { format, parseISO } from 'date-fns';

// Styles
import '../Table.css';

const CapitalTransactionTable = (props: any) => {
  return (
    <table>
      <thead>
        <tr>
          <th>S/N</th>
          <th>DESCRIPTION</th>
          <th>AMOUNT (₦)</th>
          <th>DATE</th>
        </tr>
      </thead>
      <tbody>
        {props.data?.map((data: any, index: any) => {
          let serialNumber = index + 1;
          const formattedDate = format(
            parseISO(data?.date_iso_string),
            'do MMM, yyy'
          );
          return (
            <tr key={data.id}>
              <td>{serialNumber}</td>
              <td>{data.description}</td>
              <td>
                <NumberFormat
                  value={data.amount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={''}
                />
              </td>
              <td>{formattedDate}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CapitalTransactionTable;
