// Styles
import '../Expenses/Expenses.css';

// Graphql queries
import { useGetLogsQuery } from '../../generated/graphql';

// Components
import { ViewLogsTable, ViewLogModal, TableFooter } from '../../components';
import { Skeleton } from '@mui/material';
import { useState } from 'react';
import { TStoredUser } from '../../types/user';
import { ILog } from '../../types/log';
import { useTable } from '../../hooks/useTable';

interface IProps {
  userData: TStoredUser;
}

const ClientLogsRecords = (props: IProps) => {
  const { userData } = props;
  const userId = userData.id;

  const { data, loading } = useGetLogsQuery({
    variables: {
      user: userId,
    },
    fetchPolicy: 'cache-and-network',
  });

  // State management
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [editableData, setEditableData] = useState<ILog>();

  // Pagination
  const rowsPerPage = 15;

  const setLogsData = data?.findAllLogs ? data.findAllLogs : [];

  const [activePage, setActivePage] = useState(1);
  const { calculatedRows, totalRows, totalPages } = useTable(
    setLogsData,
    activePage,
    rowsPerPage
  );

  const toggleUpdateModal = () => setIsUpdateModalOpen(!isUpdateModalOpen);

  // Get required values and display update modal
  const toggleUpdate = (record: ILog) => {
    setEditableData(record);

    toggleUpdateModal();
  };

  return (
    <>
      <div className="expenses-wrap">
        <div className="add-record-con">
          <div className="first-con">
            <h4 className="second-con-title">Daily log reports</h4>
          </div>
          <div className="second-con">
            {loading ? (
              <div style={{ display: 'flex' }}>
                <Skeleton variant="rectangular" width={300} height={70} />
                <Skeleton variant="rectangular" width={300} height={70} />
                <Skeleton variant="rectangular" width={300} height={70} />
              </div>
            ) : (
              <div>
                <ViewLogsTable
                  data={calculatedRows}
                  editRecord={toggleUpdate}
                  activePage={activePage}
                  rowsPerPage={rowsPerPage}
                />
                <TableFooter
                  activePage={activePage}
                  setActivePage={setActivePage}
                  totalRows={totalRows}
                  totalPages={totalPages}
                  rowsPerPage={rowsPerPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {isUpdateModalOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleUpdateModal}>
            X
          </div>
          <ViewLogModal editableData={editableData} />
        </div>
      )}
    </>
  );
};

export default ClientLogsRecords;
