import * as yup from 'yup';

export const modalValidationSchema = yup.object().shape({
  description: yup.string().required('This field is required'),

  quantity: yup
    .string()
    .required('This field is required')
    .matches(
      //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      /^[0-9]{1,20}?$/,
      'Please enter a valid number'
    ),
  value: yup
    .string()
    .required('This field is required')
    .matches(
      //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      /^[0-9]{1,20}?$/,
      // /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
      'Please enter a valid number'
    ),
});

export const capitalModalValidationSchema = yup.object().shape({
  description: yup.string().required('This field is required'),

  amount: yup
    .string()
    .required('This field is required')
    .matches(
      //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      /^[0-9]{1,20}?$/,
      'Please enter a valid number'
    ),
});

export const logModalValidationSchema = yup.object().shape({
  description: yup.string().required('This field is required'),
});
