// Styles
import '../Transactions/Transactions.css';

import DonCapitalRecords from './DonCapitalRecords';
import ClientCapitalRecords from './ClientCapitalRecords';

const CapitalRecords = () => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData?.role === 'don') {
    return (
      <>
        <DonCapitalRecords userData={userData} />
      </>
    );
  } else {
    return (
      <>
        <ClientCapitalRecords userData={userData} />
      </>
    );
  }
};

export default CapitalRecords;
