// Styles
import '../Transactions/Transactions.css';

import DonAnimalRecords from './DonAnimalRecords';
import ClientAnimalRecords from './ClientAnimalRecords';

const AnimalRecords = () => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData?.role === 'don') {
    return (
      <>
        <DonAnimalRecords userData={userData} />
      </>
    );
  } else {
    return (
      <>
        <ClientAnimalRecords userData={userData} />
      </>
    );
  }
};

export default AnimalRecords;
