import { useState } from 'react';

// Packages
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';

// Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// Styles
import './Modal.css';

// Validation schema
import { modalValidationSchema } from './validationSchema';

// Components
import ErrorAlert from '../ErrorAlert/ErrorAlert';

// Loading spinner
import { CircularProgress } from '@mui/material';

const Modal = (propss: any) => {
  const [isErrorAlertActive, setIsErrorAlertActive] = useState<Boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOptions = () => setIsOpen(!isOpen);

  const selectOption = (option: string) => {
    propss.setSelectedOption(option);

    toggleOptions();
  };

  return (
    <div className="main-modal-body">
      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Invalid Form Request"
        message="Please select a category"
      />
      <Formik
        initialValues={{
          description: '',
          quantity: '',
          value: '',
        }}
        // validateOnMount={true}
        validationSchema={modalValidationSchema}
        onSubmit={(values, { resetForm }) => {
          if (propss.selectedOption === '') {
            setIsErrorAlertActive(true);
          } else {
            propss.submitForm(values, resetForm);
          }
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div style={{ margin: '1rem 0' }}>
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={propss.startDate}
                onChange={(date: any) => propss.setStartDate(date)}
              />
            </div>

            <div className="dropdown-con">
              <div className="dropdown-header" onClick={toggleOptions}>
                {propss.selectedOption
                  ? propss.selectedOption
                  : 'Select a category'}{' '}
                {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </div>
              {isOpen && (
                <div className="dropdown-body">
                  {propss.CATEGORIES.map((category: any, index: any) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => selectOption(category)}
                    >
                      {category}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <textarea
              // type="text"
              name="description"
              placeholder="Description"
              onChange={props.handleChange('description')}
              onBlur={props.handleBlur('description')}
              value={props.values.description}
            />
            {props.errors.description && props.touched.description && (
              <p className="validation-error">{props.errors.description}</p>
            )}

            <input
              type="text"
              name="quantity"
              placeholder="Quantity"
              onChange={props.handleChange('quantity')}
              onBlur={props.handleBlur('quantity')}
              value={props.values.quantity}
            />
            {props.errors.quantity && props.touched.quantity && (
              <p className="validation-error">{props.errors.quantity}</p>
            )}

            <input
              type="text"
              name="value"
              placeholder="Value"
              onChange={props.handleChange('value')}
              onBlur={props.handleBlur('value')}
              value={props.values.value}
            />
            {props.errors.value && props.touched.value && (
              <p className="validation-error">{props.errors.value}</p>
            )}

            <button type="submit">
              {propss.loading ? (
                <CircularProgress style={{ color: '#fff' }} size={25} />
              ) : (
                'Add record'
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Modal;
