import * as yup from 'yup';

export const resetPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Please enter a valid Email address')
    .required('Email Address is required'),
  password: yup.string().trim().required('Password is required'),
  passwordConfirmation: yup
    .string()
    .trim()
    .required('Password Confirmation is required'),
});

export const updatePasswordValidationSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .trim()
    .required('Your current password is required'),
  password: yup.string().trim().required('Your new Password is required'),
  passwordConfirmation: yup
    .string()
    .trim()
    .required('Your new password confirmation is required'),
});
