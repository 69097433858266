// Styles
import '../Transactions/Transactions.css';

import ClientSalaryRecords from './ClientSalaryRecords';
import DonSalaryRecords from './DonSalaryRecords';

const SalaryRecords = () => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData?.role === 'don') {
    return (
      <>
        <DonSalaryRecords userData={userData} />
      </>
    );
  } else {
    return (
      <>
        <ClientSalaryRecords userData={userData} />
      </>
    );
  }
};

export default SalaryRecords;
