// Styles
import '../Transactions/Transactions.css';

import ClientFeedRecords from './ClientFeedRecords';
import DonFeedRecords from './DonFeedRecords';

const FeedRecords = () => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData?.role === 'don') {
    return (
      <>
        <DonFeedRecords userData={userData} />
      </>
    );
  } else {
    return (
      <>
        <ClientFeedRecords userData={userData} />
      </>
    );
  }
};

export default FeedRecords;
