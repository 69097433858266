// Styles
import '../Transactions/Transactions.css';

// Queries
import { useGetDrugRecordsQuery } from '../../generated/graphql';

import TransactionTable from '../../components/Table/TransactionsTable/TransactionTable';
import { Skeleton } from '@mui/material';

const ClientDrugRecords = (props: any) => {
  const { userData } = props;
  const userId = userData.id;

  const { data, loading } = useGetDrugRecordsQuery({
    variables: {
      user: userId,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div className="transactions">
      <div className="transactions-wrap">
        <div className="main-trans-con">
          <div className="trans-header-tabs-con">
            <div className="trans-tab active-trans-tab">
              Drug expense records
            </div>
          </div>
          <div className="trans-body-con">
            {loading ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton variant="rectangular" width={300} height={70} />

                <Skeleton variant="rectangular" width={300} height={70} />

                <Skeleton variant="rectangular" width={300} height={70} />
              </div>
            ) : (
              <TransactionTable data={data?.findAllDrugRecords} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientDrugRecords;
