import React from 'react';
import { Redirect } from 'react-router-dom';
import { getAccessToken } from '../../utils/accessToken';
import { MainView, Sidebar } from '../../views';

// Styles
import './Dashboard.css';

const Dashboard: React.FC = () => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="dashboard">
      <Sidebar />
      <MainView />
    </div>
  );
};

export default Dashboard;
