// Styles
import './Modal.css';

const ViewLogModal = (propss: any) => {
  return (
    <div className="main-modal-body">
      <div className="log-modal-wrapper">
        <div className="log-details-con">
          <h2 className="log-details-title">Log Details</h2>
          <p className="log-details">{propss.editableData.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ViewLogModal;
