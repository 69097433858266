import { useState } from 'react';

// Packages
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';

// Styles
import './Modal.css';

// Validation schema
import { capitalModalValidationSchema } from './validationSchema';

// Components
import ErrorAlert from '../ErrorAlert/ErrorAlert';

// Loading spinner
import { CircularProgress } from '@mui/material';

const CapitalModal = (propss: any) => {
  const [isErrorAlertActive, setIsErrorAlertActive] = useState<Boolean>(false);

  return (
    <div className="main-modal-body">
      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Invalid Form Request"
        message="Please select a category"
      />
      <Formik
        initialValues={{
          description: '',
          amount: '',
        }}
        // validateOnMount={true}
        validationSchema={capitalModalValidationSchema}
        onSubmit={(values, { resetForm }) => {
          propss.submitForm(values, resetForm);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="dropdown-con">
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={propss.startDate}
                onChange={(date: any) => propss.setStartDate(date)}
              />
            </div>

            <textarea
              // type="text"
              name="description"
              placeholder="Description"
              onChange={props.handleChange('description')}
              onBlur={props.handleBlur('description')}
              value={props.values.description}
            />
            {props.errors.description && props.touched.description && (
              <p className="validation-error">{props.errors.description}</p>
            )}

            <input
              type="text"
              name="amount"
              placeholder="Amount"
              onChange={props.handleChange('amount')}
              onBlur={props.handleBlur('amount')}
              value={props.values.amount}
            />
            {props.errors.amount && props.touched.amount && (
              <p className="validation-error">{props.errors.amount}</p>
            )}

            <button type="submit">
              {propss.loading ? (
                <CircularProgress style={{ color: '#fff' }} size={25} />
              ) : (
                'Add record'
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CapitalModal;
