import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  Dashboard,
  ForgotPassword,
  Login,
  Register,
  ResetPassword,
} from '../pages';

// Utils
import { getAccessToken } from '../utils/accessToken';

function Routes() {
  const accessToken = getAccessToken();

  return (
    <BrowserRouter>
      <div className="routes">
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return accessToken ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/Reset-password" component={ResetPassword} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default Routes;
