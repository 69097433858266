import { useState } from 'react';

// Graphql queries
import { useGetLogsQuery, useGetUsersQuery } from '../../generated/graphql';

// Styles
import '../Expenses/Expenses.css';

// Components
import { ViewLogsTable, ViewLogModal, TableFooter } from '../../components';
import { Skeleton } from '@mui/material';
import { TStoredUser } from '../../types/user';
import { useTable } from '../../hooks/useTable';
import { ILog } from '../../types/log';

interface IProps {
  userData: TStoredUser;
}

const DonLogsRecords = (props: IProps) => {
  const { userData } = props;

  const [activePage, setActivePage] = useState(1);
  const [activeUserCard, setActiveUserCard] = useState(userData?.id);

  const toggleActiveUserCard = (userId: string) => {
    if (activePage > 1) {
      setActivePage(1);
    }

    setActiveUserCard(userId);
  };

  // Queries and Mutations
  const { data: usersData, loading: loadingUsersData } = useGetUsersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: logsData, loading: logsLoading } = useGetLogsQuery({
    variables: {
      user: activeUserCard,
    },
    fetchPolicy: 'cache-and-network',
  });

  // State management
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [editableData, setEditableData] = useState<ILog>();

  // Pagination
  const rowsPerPage = 15;

  const setLogsData = logsData?.findAllLogs ? logsData.findAllLogs : [];

  const { calculatedRows, totalRows, totalPages } = useTable(
    setLogsData,
    activePage,
    rowsPerPage
  );

  const toggleUpdateModal = () => setIsUpdateModalOpen(!isUpdateModalOpen);

  // Get required values and display update modal
  const toggleUpdate = (record: ILog) => {
    setEditableData(record);

    toggleUpdateModal();
  };

  return (
    <>
      <div className="expenses-don-variant-wrapper">
        {loadingUsersData ? (
          <div className="skeleton-ui-wrapper">
            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={70} />
            </div>

            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={70} />
            </div>

            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={300} />
            </div>
          </div>
        ) : (
          <>
            <div className="don-variant-user-wrapper">
              {usersData &&
                usersData.users
                  .sort((a, b) =>
                    a.role > b.role ? -1 : b.role > a.role ? 1 : 0
                  )
                  .map((user) => {
                    let userName =
                      user.first_name[user.first_name.length - 1] === 's'
                        ? `${user.first_name}' record`
                        : `${user.first_name}'s record`;

                    // console.log(activeUserCard, user?.id);

                    return (
                      <div
                        key={user?.id}
                        className={
                          activeUserCard === user?.id
                            ? 'active-expenses-don-user-card'
                            : 'inactive-expenses-don-user-card'
                        }
                        onClick={() => toggleActiveUserCard(user?.id)}
                      >
                        <h2>{userName}</h2>
                      </div>
                    );
                  })}
            </div>

            <div className="expenses-wrap">
              <div className="add-record-con">
                <div className="first-con">
                  <h4 className="second-con-title">Daily log reports</h4>
                </div>
                <div className="second-con">
                  {logsLoading ? (
                    <div style={{ display: 'flex' }}>
                      <Skeleton variant="rectangular" width={300} height={70} />
                      <Skeleton variant="rectangular" width={300} height={70} />
                      <Skeleton variant="rectangular" width={300} height={70} />
                    </div>
                  ) : (
                    <div>
                      <ViewLogsTable
                        data={calculatedRows}
                        editRecord={toggleUpdate}
                        activePage={activePage}
                        rowsPerPage={rowsPerPage}
                      />
                      <TableFooter
                        activePage={activePage}
                        setActivePage={setActivePage}
                        totalRows={totalRows}
                        totalPages={totalPages}
                        rowsPerPage={rowsPerPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isUpdateModalOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleUpdateModal}>
            X
          </div>
          <ViewLogModal editableData={editableData} />
        </div>
      )}
    </>
  );
};

export default DonLogsRecords;
