// Styles
import '../Transactions/Transactions.css';

import DonLogsRecords from './DonLogsRecords';
import ClientLogsRecords from './ClientLogsRecords';
import { TStoredUser } from '../../types/user';

const Logs = () => {
  // @ts-ignore
  const userData: TStoredUser = JSON.parse(localStorage.getItem('userData'));

  if (userData?.role === 'don') {
    return (
      <>
        <DonLogsRecords userData={userData} />
      </>
    );
  } else {
    return (
      <>
        <ClientLogsRecords userData={userData} />
      </>
    );
  }
};

export default Logs;
