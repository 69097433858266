import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// import { useRegisterMutation } from '../../generated/graphql';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  role: string;
}

const Register: React.FC<RouteComponentProps> = ({ history }) => {
  const [values, setValues] = useState<FormValues>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: 'staff',
  });
  // const [register] = useRegisterMutation();

  const SubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // let first_name = values.firstName;
    // let last_name = values.lastName;
    // let email = values.email;
    // let password = values.password;
    // let role = values.role;

    // const response = await register({
    //   variables: {
    //     first_name,
    //     last_name,
    //     email,
    //     password,
    //     phone_number,
    //     salary,
    //     role,
    //   },
    // });

    // response?.data?.register === true
    //   ? history.push('/')
    //   : alert('Login Failed!');
  };

  return (
    <div className="register">
      <form onSubmit={SubmitForm}>
        <input
          value={values.firstName}
          placeholder="First Name"
          onChange={(e) =>
            setValues({
              ...values,
              firstName: e.target.value,
            })
          }
        />
        <input
          value={values.lastName}
          placeholder="Last Name"
          onChange={(e) =>
            setValues({
              ...values,
              lastName: e.target.value,
            })
          }
        />
        <input
          value={values.email}
          placeholder="email"
          onChange={(e) =>
            setValues({
              ...values,
              email: e.target.value,
            })
          }
        />
        <input
          type="password"
          value={values.password}
          placeholder="password"
          onChange={(e) =>
            setValues({
              ...values,
              password: e.target.value,
            })
          }
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Register;
