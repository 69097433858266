import { Formik } from 'formik';

// Styles
import './Modal.css';

// Validation schema
import { staffModalValidationSchema } from './userValidationSchema';

// Loading spinner
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import InputComponent from '../InputComponent';

const RegisterClientModal = (propss: any) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(false);
  const togglePasswordVisibility = () => setIsPasswordHidden(!isPasswordHidden);

  return (
    <div className="main-modal-body">
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          phoneNumber: '',
          salary: '0',
          role: 'client',
        }}
        // validateOnMount={true}
        validationSchema={staffModalValidationSchema}
        onSubmit={(values, { resetForm }) => {
          propss.submitForm(values, resetForm);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <input
              type="text"
              name="firstName"
              placeholder="First name"
              onChange={props.handleChange('firstName')}
              onBlur={props.handleBlur('firstName')}
              value={props.values.firstName}
            />
            {props.errors.firstName && props.touched.firstName && (
              <p className="validation-error">{props.errors.firstName}</p>
            )}

            <input
              type="text"
              name="lastName"
              placeholder="Last name"
              onChange={props.handleChange('lastName')}
              onBlur={props.handleBlur('lastName')}
              value={props.values.lastName}
            />
            {props.errors.lastName && props.touched.lastName && (
              <p className="validation-error">{props.errors.lastName}</p>
            )}

            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={props.handleChange('email')}
              onBlur={props.handleBlur('email')}
              value={props.values.email.trim()}
            />
            {props.errors.email && props.touched.email && (
              <p className="validation-error">{props.errors.email}</p>
            )}

            {/* <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={props.handleChange('password')}
              onBlur={props.handleBlur('password')}
              value={props.values.password.trim()}
            /> */}
            <InputComponent
              type="password"
              name="password"
              placeholder="Password"
              onChange={props.handleChange('password')}
              onBlur={props.handleBlur('password')}
              value={props.values.password.trim()}
              isPasswordHidden={isPasswordHidden}
              togglePasswordVisibility={togglePasswordVisibility}
            />
            {props.errors.password && props.touched.password && (
              <p className="validation-error">{props.errors.password}</p>
            )}

            <input
              type="text"
              name="phoneNumber"
              placeholder="Phone number"
              onChange={props.handleChange('phoneNumber')}
              onBlur={props.handleBlur('phoneNumber')}
              value={props.values.phoneNumber}
            />
            {props.errors.phoneNumber && props.touched.phoneNumber && (
              <p className="validation-error">{props.errors.phoneNumber}</p>
            )}

            <button type="submit">
              {propss.loading ? (
                <CircularProgress style={{ color: '#fff' }} size={25} />
              ) : (
                'Add Client'
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default RegisterClientModal;
