// Styles
import '../Transactions/Transactions.css';

// Queries
import {
  useGetCapitalRecordsQuery,
  useGetUsersQuery,
} from '../../generated/graphql';

// import TransactionTable from '../../components/Table/TransactionsTable/TransactionTable';
import CapitalTransactionTable from '../../components/Table/TransactionsTable/CapitalTransactionTable';
import { useState } from 'react';
import { Skeleton } from '@mui/material';

const DonCapitalRecords = (props: any) => {
  const { userData } = props;

  const [activeUserCard, setActiveUserCard] = useState<string>(userData?.id);
  const toggleActiveUserCard = (userId: string) => {
    setActiveUserCard(userId);
  };

  const { data, loading: loadingUsersData } = useGetUsersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: capitalData, loading: capitalLoading } =
    useGetCapitalRecordsQuery({
      variables: {
        user: activeUserCard,
      },
      fetchPolicy: 'cache-and-network',
    });

  return (
    <div className="expenses-don-variant-wrapper">
      {loadingUsersData ? (
        <div className="skeleton-ui-wrapper">
          <div className="skeleton-ui">
            <Skeleton variant="rectangular" width={900} height={70} />
          </div>

          <div className="skeleton-ui">
            <Skeleton variant="rectangular" width={900} height={70} />
          </div>

          <div className="skeleton-ui">
            <Skeleton variant="rectangular" width={900} height={300} />
          </div>
        </div>
      ) : (
        <>
          <div className="don-variant-user-wrapper">
            {data &&
              data.users
                .sort((a, b) =>
                  a.role > b.role ? -1 : b.role > a.role ? 1 : 0
                )
                .map((user) => {
                  let userName =
                    user.first_name[user.first_name.length - 1] === 's'
                      ? `${user.first_name}' record`
                      : `${user.first_name}'s record`;

                  // console.log(activeUserCard, user?.id);

                  return (
                    <div
                      key={user?.id}
                      className={
                        activeUserCard === user?.id
                          ? 'active-expenses-don-user-card'
                          : 'inactive-expenses-don-user-card'
                      }
                      onClick={() => toggleActiveUserCard(user?.id)}
                    >
                      <h2>{userName}</h2>
                    </div>
                  );
                })}
          </div>

          <div className="transactions-wrap">
            <div className="main-trans-con">
              <div className="trans-header-tabs-con">
                <div className="trans-tab active-trans-tab">
                  Capital records
                </div>
              </div>
              <div className="trans-body-con">
                {/* {activeTab.salesTab && (
              <TransactionTable data={salesData?.findAllSalesRecords} />
            )} */}
                {capitalLoading ? (
                  <div style={{ display: 'flex' }}>
                    <Skeleton variant="rectangular" width={300} height={70} />

                    <Skeleton variant="rectangular" width={300} height={70} />

                    <Skeleton variant="rectangular" width={300} height={70} />
                  </div>
                ) : (
                  <CapitalTransactionTable
                    data={capitalData?.findAllCapitalRecords}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DonCapitalRecords;
