import { useState } from 'react';

import { Formik } from 'formik';
import { CircularProgress } from '@mui/material';
// Styles
import '../../constants/theme.css';
import '../../pages/Login/Login.css';
import './Settings.css';

// Mutations
import { useUpdateUserPasswordMutation } from '../../generated/graphql';

// Validation schema
import { updatePasswordValidationSchema } from '../../pages/ResetPassword/validationSchema';

// Components
import { ErrorAlert, SuccessAlert } from '../../components';
import InputComponent from '../../components/InputComponent';

interface FormValues {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

const Settings = () => {
  const [loading, setLoading] = useState<any>(false);
  const [isErrorAlertActive, setIsErrorAlertActive] = useState<Boolean>(false);
  const [isSuccessAlertActive, setIsSuccessAlertActive] =
    useState<Boolean>(false);
  const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] =
    useState<boolean>(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(false);
  const [isPasswordConfirmationHidden, setIsPasswordConfirmationHidden] =
    useState<boolean>(false);

  const toggleCurrentPasswordVisibility = () =>
    setIsCurrentPasswordHidden(!isCurrentPasswordHidden);
  const togglePasswordVisibility = () => setIsPasswordHidden(!isPasswordHidden);
  const togglePasswordConfirmationVisibility = () =>
    setIsPasswordConfirmationHidden(!isPasswordConfirmationHidden);

  const [updateUserPassword] = useUpdateUserPasswordMutation();

  const getUser: any = () => {
    // @ts-ignore
    return JSON.parse(localStorage.getItem('userData'));
  };

  const submitForm = async (values: FormValues, resetForm: any) => {
    setLoading(true);

    let user = getUser();

    let updateUserPasswordId = user?.id;
    let currentPassword = values.currentPassword;
    let newPassword = values.password;
    let newPasswordConfirmation = values.passwordConfirmation;

    try {
      const response: any = await updateUserPassword({
        variables: {
          updateUserPasswordId,
          currentPassword,
          newPassword,
          newPasswordConfirmation,
        },
      });

      if (response && response.data) {
        resetForm();

        setIsSuccessAlertActive(true);
      }
    } catch (error) {
      setIsErrorAlertActive(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="settings">
      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request Failed"
        message="Could not update your password, please try again later"
      />

      <SuccessAlert
        isSuccessAlertActive={isSuccessAlertActive}
        setIsSuccessAlertActive={setIsSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your password has been updated!"
      />
      <div className="form-con">
        <Formik
          initialValues={{
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
          }}
          validateOnMount={true}
          validationSchema={updatePasswordValidationSchema}
          onSubmit={(values, { resetForm }) => submitForm(values, resetForm)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <h2>Update Password</h2>
              <InputComponent
                type="password"
                name="currentPassword"
                placeholder="Current password"
                onChange={props.handleChange('currentPassword')}
                onBlur={props.handleBlur('currentPassword')}
                value={props.values.currentPassword.trim()}
                isPasswordHidden={isCurrentPasswordHidden}
                togglePasswordVisibility={toggleCurrentPasswordVisibility}
              />
              {props.errors.currentPassword &&
                props.touched.currentPassword && (
                  <p className="errors">{props.errors.currentPassword}</p>
                )}

              <InputComponent
                type="password"
                name="password"
                placeholder="New password"
                onChange={props.handleChange('password')}
                onBlur={props.handleBlur('password')}
                value={props.values.password.trim()}
                isPasswordHidden={isPasswordHidden}
                togglePasswordVisibility={togglePasswordVisibility}
              />
              {props.errors.password && props.touched.password && (
                <p className="errors">{props.errors.password}</p>
              )}

              <InputComponent
                type="password"
                name="passwordConfirmation"
                placeholder="New password confirmation"
                onChange={props.handleChange('passwordConfirmation')}
                onBlur={props.handleBlur('passwordConfirmation')}
                value={props.values.passwordConfirmation.trim()}
                isPasswordHidden={isPasswordConfirmationHidden}
                togglePasswordVisibility={togglePasswordConfirmationVisibility}
              />
              {props.errors.passwordConfirmation &&
                props.touched.passwordConfirmation && (
                  <p className="errors">{props.errors.passwordConfirmation}</p>
                )}

              <button type="submit" disabled={loading!}>
                {loading ? (
                  <CircularProgress style={{ color: '#fff' }} size={25} />
                ) : (
                  'Update'
                )}
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Settings;
