import ClientOverviewComponent from './ClientOverviewComponent';
import DonOverviewComponent from './DonOverviewComponent';
import './Overview.css';

const Overview = () => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData.role === 'don') {
    return (
      <div className="overview">
        <DonOverviewComponent />
      </div>
    );
  } else {
    return (
      <div className="overview">
        <ClientOverviewComponent userData={userData} />
      </div>
    );
  }
};

export default Overview;
