// Packages
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik, FormikState } from 'formik';

// Styles
import './Modal.css';

// Validation schema
import { logModalValidationSchema } from './validationSchema';

// Loading spinner
import { CircularProgress } from '@mui/material';
import { ILog } from '../../types/log';

interface IProps {
  submitForm: (
    values: { description: string },
    resetForm: (
      nextState?: Partial<FormikState<{ description: string }>> | undefined
    ) => void
  ) => Promise<void>;
  startDate: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  editableData: ILog | undefined;
  loading: boolean;
}

const UpdateLogModal = (propss: IProps) => {
  return (
    <div className="main-modal-body">
      <Formik
        initialValues={{
          description: propss.editableData?.description,
        }}
        // validateOnMount={true}
        validationSchema={logModalValidationSchema}
        onSubmit={(values: any, { resetForm }) => {
          propss.submitForm(values, resetForm);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="dropdown-con">
              <DatePicker
                dateFormat="dd-MM-yyyy"
                selected={propss.startDate}
                onChange={(date: any) => propss.setStartDate(date)}
              />
            </div>

            <textarea
              // type="text"
              name="description"
              placeholder={propss.editableData?.description}
              onChange={props.handleChange('description')}
              onBlur={props.handleBlur('description')}
              value={props.values.description}
            />
            {props.errors.description && props.touched.description && (
              <p className="validation-error">{props.errors.description}</p>
            )}

            <button type="submit">
              {propss.loading ? (
                <CircularProgress style={{ color: '#fff' }} size={25} />
              ) : (
                'Update Log'
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateLogModal;
