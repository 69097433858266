import React, { useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { CircularProgress } from '@mui/material';
import queryString from 'query-string';

// Styles
import '../../constants/theme.css';
import '../Login/Login.css';

// Images
import brandLogo from '../../assets/SMV_logo_white.png';

// Mutations
// import { useResetPasswordMutation } from '../../generated/graphql';

// Validation schema
import { resetPasswordValidationSchema } from './validationSchema';

// Components
import { ErrorAlert, SuccessAlert } from '../../components';

interface FormValues {
  email: string;
  password: string;
  passwordConfirmation: string;
}

const ResetPassword: React.FC<RouteComponentProps> = ({ history }) => {
  let location = useLocation();

  const [loading, setLoading] = useState<any>(false);
  const [isErrorAlertActive, setIsErrorAlertActive] = useState<Boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccessAlertActive, setIsSuccessAlertActive] =
    useState<Boolean>(false);

  // const [resetPassword] = useResetPasswordMutation();

  const navToLogin = () => {
    history.push('/login');
  };

  //   const checkPassword = async (password: string, passwordConfirm: string) => {
  //     try {
  //       if (password !== passwordConfirm) {
  //         //   setErrorMessage('You password confirmation does not match!');
  //         //   setIsErrorAlertActive(true);
  //         throw new Error('You password confirmation does not match!');
  //       }
  //     } catch (error) {
  //       setErrorMessage(error.message);
  //       //   setIsErrorAlertActive(true);
  //     }
  //   };

  // get query params from url and parse to an object
  //   const passwordToken: any = location.search;
  //   const urlSearchParams = new URLSearchParams(window.location.search);

  //   const params: any = window.location.search;
  const params: any = queryString.parse(location.search.substring(1));

  const submitForm = async (values: FormValues, resetForm: any) => {
    // e: React.FormEvent<HTMLFormElement>
    // e.preventDefault();
    setLoading(true);

    // await checkPassword(values.password, values.passwordConfirmation);

    // let email = values.email.toLowerCase();
    // let new_password = values.password;
    // let new_password_confirmation = values.passwordConfirmation;
    // let reset_password_token = params.token;

    try {
      // const response: any = await resetPassword({
      //   variables: {
      //     email,
      //     new_password,
      //     new_password_confirmation,
      //     reset_password_token,
      //   },
      // });

      // if (response && response.data) {
      //   resetForm();

      //   setIsSuccessAlertActive(true);

      //   setTimeout(navToLogin, 5000);
      // }

      setIsSuccessAlertActive(true);
    } catch (error) {
      setErrorMessage('Invalid Request');
      setIsErrorAlertActive(true);
    } finally {
      setLoading(false);
    }
  };

  const navToHome = () => {
    history.push('/');
  };

  return (
    <div className="login">
      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request Failed"
        message={errorMessage}
      />

      <SuccessAlert
        isSuccessAlertActive={isSuccessAlertActive}
        setIsSuccessAlertActive={setIsSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your password has been updated!"
      />
      <div className="form-con">
        <div className="app-logo" onClick={navToHome}>
          <img src={brandLogo} alt="Brand Logo" />
        </div>
        <Formik
          initialValues={{ email: '', password: '', passwordConfirmation: '' }}
          validateOnMount={true}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={(values, { resetForm }) => submitForm(values, resetForm)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <h2>PASSWORD RESET</h2>
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={props.handleChange('email')}
                onBlur={props.handleBlur('email')}
                value={props.values.email.trim()}
              />
              {props.errors.email && props.touched.email && (
                <p className="errors">{props.errors.email}</p>
              )}

              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={props.handleChange('password')}
                onBlur={props.handleBlur('password')}
                value={props.values.password.trim()}
              />
              {props.errors.password && props.touched.password && (
                <p className="errors">{props.errors.password}</p>
              )}

              <input
                type="password"
                name="passwordConfirmation"
                placeholder="Password confirmation"
                onChange={props.handleChange('passwordConfirmation')}
                onBlur={props.handleBlur('passwordConfirmation')}
                value={props.values.passwordConfirmation.trim()}
              />
              {props.errors.passwordConfirmation &&
                props.touched.passwordConfirmation && (
                  <p className="errors">{props.errors.passwordConfirmation}</p>
                )}

              <button type="submit" disabled={loading!}>
                {loading ? (
                  <CircularProgress style={{ color: '#fff' }} size={25} />
                ) : (
                  'Reset'
                )}
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
