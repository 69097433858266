import NumberFormat from 'react-number-format';

// import React from 'react';
import './Overview.css';

// Icons
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';

import Skeleton from '@mui/material/Skeleton';
import { useGetUsersQuery } from '../../generated/graphql';

const DonOverviewComponent = () => {
  const { data, loading } = useGetUsersQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return (
      <div className="overview">
        <div className="overview-wrapper">
          <Skeleton variant="rectangular" width={300} height={200} />

          <Skeleton variant="rectangular" width={300} height={200} />

          <Skeleton variant="rectangular" width={300} height={200} />
        </div>
      </div>
    );
  }

  return (
    <div className="don-main-wrapper">
      {data &&
        data.users
          .sort((a, b) => (a.role > b.role ? -1 : b.role > a.role ? 1 : 0))
          ?.map((user) => {
            let capitalAccBalance = 0;
            let expensesAccBalance = 0;
            let salesAccBalance = 0;

            let userName =
              user.first_name[user.first_name.length - 1] === 's'
                ? `${user.first_name}' accounts`
                : `${user.first_name}'s accounts`;

            user.accounts.forEach((account) => {
              if (account.type === 'capital') {
                capitalAccBalance = account.balance;
              } else if (account.type === 'expenses') {
                expensesAccBalance = account.balance;
              } else {
                salesAccBalance = account.balance;
              }
            });

            return (
              <div key={user?.id}>
                <h1 style={{ margin: '2em 0 1em 0' }}>{userName}</h1>

                <div className="don-overview-wrapper">
                  <div className="capital-acc-card">
                    <h3 className="acc-title">Capital account</h3>
                    <span className="acc-logo-bg">
                      <AccountBalanceOutlinedIcon />
                    </span>
                    <h3 className="acct-balance">
                      <NumberFormat
                        value={capitalAccBalance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'₦'}
                      />
                    </h3>
                  </div>

                  <div className="expenses-acc-card">
                    <h3 className="acc-title">Expenses account</h3>
                    <span className="acc-logo-bg">
                      <PaymentOutlinedIcon />
                    </span>
                    <h3 className="acct-balance">
                      <NumberFormat
                        value={expensesAccBalance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'₦'}
                      />
                    </h3>
                  </div>

                  <div className="sales-acc-card">
                    <h3 className="acc-title">Sales account</h3>
                    <span className="acc-logo-bg">
                      <AccountBalanceWalletOutlinedIcon />
                    </span>
                    <h3 className="acct-balance">
                      <NumberFormat
                        value={salesAccBalance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'₦'}
                      />
                    </h3>
                  </div>
                </div>
              </div>
            );
          })}
    </div>
  );
};

export default DonOverviewComponent;
