import { combineReducers } from 'redux';
import { appStateReducer, getUserReducer } from '.';
// import { appStateReducer } from './reducers';

export const reducers = combineReducers({
  appState: appStateReducer,
  getUser: getUserReducer,
});

// The RootState is required to set the type of useSelector hook.
export type RootState = ReturnType<typeof reducers>;
