import { Dispatch } from 'redux';
import { Action, ActionType } from '../actionTypes/appStateActionTypes';

// Activate Overview State
export const activateOverviewState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_OVERVIEW_STATE,
    });
  };
};

// Activate Expenses State
export const activateExpensesState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_EXPENSES_STATE,
    });
  };
};

// Activate Sales State
export const activateSalesState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_SALES_STATE,
    });
  };
};

// Activate Capital State
export const activateCapitalState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_CAPITAL_STATE,
    });
  };
};

// Activate Transactions State
export const activateTransactionsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_TRANSACTIONS_STATE,
    });
  };
};

// Activate Admin State
export const activateAdminState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_ADMIN_STATE,
    });
  };
};

// Activate Customers State
export const activateCustomersState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_CUSTOMERS_STATE,
    });
  };
};

// Activate Settings State
export const activateSettingsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_SETTINGS_STATE,
    });
  };
};

export const activateStaffState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_STAFF_STATE,
    });
  };
};

export const activateClientState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_CLIENT_STATE,
    });
  };
};

export const activateCapitalRecordsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_CAPITAL_RECORDS_STATE,
    });
  };
};

export const activateSalesRecordsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_SALES_RECORDS_STATE,
    });
  };
};

export const activateAnimalRecordsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_ANIMAL_RECORDS_STATE,
    });
  };
};

export const activateDrugRecordsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_DRUG_RECORDS_STATE,
    });
  };
};

export const activateFeedRecordsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_FEED_RECORDS_STATE,
    });
  };
};

export const activateMaintenanceRecordsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_MAINTENANCE_RECORDS_STATE,
    });
  };
};

export const activateMiscellaneousRecordsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_MISCELLANEOUS_RECORDS_STATE,
    });
  };
};

export const activateSalaryRecordsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_SALARY_RECORDS_STATE,
    });
  };
};

export const activateDailyLogsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_DAILY_LOGS_STATE,
    });
  };
};

export const activateLogsState = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ACTIVATE_LOGS_STATE,
    });
  };
};
