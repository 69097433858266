import React, { useState } from 'react';

// Packages
import { format, parseISO } from 'date-fns';

// Graphql queries & mutation
import {
  useGetLogsQuery,
  useCreateLogMutation,
  useDeleteLogByIdMutation,
  useUpdateLogByIdMutation,
  useGetUsersQuery,
  GetUsersDocument,
} from '../../generated/graphql';

// Hooks
import { useDispatch } from 'react-redux';

// Styles
import '../Expenses/Expenses.css';

// Components
import {
  ErrorAlert,
  SuccessAlert,
  LogsTable,
  CreateLogModal,
  UpdateLogModal,
  DeleteLogModal,
  TableFooter,
} from '../../components';

// Action creators
import { activateLogsState } from '../../app/features/actionCreators/appStateActionCreators';
import { Skeleton } from '@mui/material';
import { ILog } from '../../types/log';
import { FormikState } from 'formik';
import { useTable } from '../../hooks/useTable';
import { TStoredUser } from '../../types/user';

const DailyLogs = () => {
  // Initialize useDispatch hook
  const dispatch = useDispatch();

  // @ts-ignore
  const userData: TStoredUser = JSON.parse(localStorage.getItem('userData'));
  const [activePage, setActivePage] = useState(1);
  const [activeUserCard, setActiveUserCard] = useState(userData?.id);

  const toggleActiveUserCard = (userId: string) => {
    if (activePage > 1) {
      setActivePage(1);
    }

    setActiveUserCard(userId);
  };

  // Queries and Mutations
  const { data: usersData, loading: loadingUsersData } = useGetUsersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: logsData,
    loading: logsLoading,
    refetch,
  } = useGetLogsQuery({
    variables: {
      user: activeUserCard,
    },
    fetchPolicy: 'network-only',
  });
  const [createLog] = useCreateLogMutation();
  const [updateLogById] = useUpdateLogByIdMutation();
  const [deleteLogById] = useDeleteLogByIdMutation();

  // State management
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editableData, setEditableData] = useState<ILog>();
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [isSuccessAlertActive, setIsSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isUpdateSuccessAlertActive, setIsUpdateSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isDeleteSuccessAlertActive, setIsDeleteSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isErrorAlertActive, setIsErrorAlertActive] =
    React.useState<Boolean>(false);
  const [isUpdateErrorAlertActive, setIsUpdateErrorAlertActive] =
    React.useState<Boolean>(false);
  const [isDeleteErrorAlertActive, setIsDeleteErrorAlertActive] =
    React.useState<Boolean>(false);

  // Pagination
  const rowsPerPage = 10;

  const setLogsData = logsData?.findAllLogs ? logsData.findAllLogs : [];

  const { calculatedRows, totalRows, totalPages } = useTable(
    setLogsData,
    activePage,
    rowsPerPage
  );

  const toggleModal = () => setIsOpen(!isOpen);

  const toggleUpdateModal = () => setIsUpdateModalOpen(!isUpdateModalOpen);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  // Create a record
  const submitForm = async (
    values: { description: string },
    resetForm: (
      nextState?: Partial<FormikState<{ description: string }>> | undefined
    ) => void
  ) => {
    setLoading(true);

    // Convert new Date() object to ISOString
    const isoDate = startDate.toISOString();

    // Parse the ISOString to desired format
    const formattedDate = format(parseISO(isoDate), 'do MMM, yyy');

    let date: string = formattedDate;
    let description = values.description;
    let date_iso_string = isoDate;

    try {
      await createLog({
        variables: {
          date,
          description,
          user: activeUserCard,
          date_iso_string,
        },
        refetchQueries: [
          // { query: GetLogsDocument }
          { query: GetUsersDocument },
        ],
      });

      refetch({
        user: activeUserCard,
      });

      // Display Success alert
      setIsSuccessAlertActive(true);

      // Close Modal
      toggleModal();

      //Reset Form
      resetForm();
    } catch (error) {
      // Display Error alert
      setIsErrorAlertActive(true);
    } finally {
      // Reset loading state
      setLoading(false);
    }
  };

  // Update the record
  const editRecord = async (
    values: { description: string },
    resetForm: (
      nextState?: Partial<FormikState<{ description: string }>> | undefined
    ) => void
  ) => {
    setLoading(true);

    // Convert new Date() object to ISOString
    const isoDate = startDate.toISOString();

    // Parse the ISOString to desired format
    const formattedDate = format(parseISO(isoDate), 'MMM do, yyy');

    let updateLogByIdId = editableData!.id;
    let date: string = formattedDate;
    let description = values.description;
    let date_iso_string = isoDate;

    try {
      await updateLogById({
        variables: {
          updateLogByIdId,
          date,
          description,
          date_iso_string,
        },
        refetchQueries: [
          // { query: GetLogsDocument }
          { query: GetUsersDocument },
        ],
      });

      refetch({
        user: activeUserCard,
      });

      // Display Success alert
      setIsUpdateSuccessAlertActive(true);

      // Close Modal
      toggleUpdateModal();

      //Reset Form
      resetForm();
    } catch (error) {
      // Display error alert
      setIsUpdateErrorAlertActive(true);
    } finally {
      // Reset Loading state
      setLoading(false);
    }
  };

  // Delete a record
  const deleteRecord = () => {
    setLoading(true);

    let deleteLogByIdId = editableData!.id;

    try {
      deleteLogById({
        variables: {
          deleteLogByIdId,
        },
        refetchQueries: [
          // { query: GetLogsDocument }
          { query: GetUsersDocument },
        ],
      });

      refetch({
        user: activeUserCard,
      });

      // Display success alert
      setIsDeleteSuccessAlertActive(true);

      // Close Modal
      toggleDeleteModal();
    } catch (error) {
      // Display error alert
      setIsDeleteErrorAlertActive(true);
    } finally {
      setLoading(false);
    }
  };

  // Toggle Logs view state
  const toggleLogsState = async () => {
    await dispatch(activateLogsState());
  };

  // Get required values and display update modal
  const toggleUpdate = (record: ILog) => {
    setStartDate(new Date(record?.date_iso_string));
    setEditableData(record);

    toggleUpdateModal();
  };

  const toggleDelete = (record: ILog) => {
    setEditableData(record);

    toggleDeleteModal();
  };

  return (
    <>
      <SuccessAlert
        isSuccessAlertActive={isSuccessAlertActive}
        setIsSuccessAlertActive={setIsSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your Log has been added!"
      />
      <SuccessAlert
        isSuccessAlertActive={isUpdateSuccessAlertActive}
        setIsSuccessAlertActive={setIsUpdateSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your Log has been updated!"
      />
      <SuccessAlert
        isSuccessAlertActive={isDeleteSuccessAlertActive}
        setIsSuccessAlertActive={setIsDeleteSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your Log has been deleted!"
      />

      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not add Log, please try again later!"
      />
      <ErrorAlert
        isErrorAlertActive={isUpdateErrorAlertActive}
        setIsErrorAlertActive={setIsUpdateErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not update Log, please try again later!"
      />
      <ErrorAlert
        isErrorAlertActive={isDeleteErrorAlertActive}
        setIsErrorAlertActive={setIsDeleteErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not delete Log, please try again later!"
      />

      <div className="expenses-don-variant-wrapper">
        {loadingUsersData ? (
          <div className="skeleton-ui-wrapper">
            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={70} />
            </div>

            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={70} />
            </div>

            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={300} />
            </div>
          </div>
        ) : (
          <>
            <div className="don-variant-user-wrapper">
              {usersData &&
                usersData.users
                  .sort((a, b) =>
                    a.role > b.role ? -1 : b.role > a.role ? 1 : 0
                  )
                  .map((user) => {
                    let userName =
                      user.first_name[user.first_name.length - 1] === 's'
                        ? `${user.first_name}' record`
                        : `${user.first_name}'s record`;

                    return (
                      <div
                        key={user?.id}
                        className={
                          activeUserCard === user?.id
                            ? 'active-expenses-don-user-card'
                            : 'inactive-expenses-don-user-card'
                        }
                        onClick={() => toggleActiveUserCard(user?.id)}
                      >
                        <h2>{userName}</h2>
                      </div>
                    );
                  })}
            </div>

            <div className="expenses-wrap">
              <div className="add-record-con">
                <div className="first-con">
                  <h4 className="second-con-title">Recently added Logs</h4>
                  <button onClick={toggleModal}>Add a new Log</button>
                </div>
                <div className="second-con">
                  {logsLoading ? (
                    <div style={{ display: 'flex' }}>
                      <Skeleton variant="rectangular" width={300} height={70} />
                      <Skeleton variant="rectangular" width={300} height={70} />
                      <Skeleton variant="rectangular" width={300} height={70} />
                    </div>
                  ) : (
                    <div>
                      <LogsTable
                        data={calculatedRows}
                        editRecord={toggleUpdate}
                        deleteRecord={toggleDelete}
                        activePage={activePage}
                        rowsPerPage={rowsPerPage}
                      />
                      <TableFooter
                        activePage={activePage}
                        setActivePage={setActivePage}
                        totalRows={totalRows}
                        totalPages={totalPages}
                        rowsPerPage={rowsPerPage}
                      />
                    </div>
                  )}
                </div>
                <h4 className="view-trans-link" onClick={toggleLogsState}>
                  View all logs
                </h4>
              </div>
            </div>
          </>
        )}
      </div>
      {isOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleModal}>
            X
          </div>
          <CreateLogModal
            submitForm={submitForm}
            startDate={startDate}
            setStartDate={setStartDate}
            loading={loading}
          />
        </div>
      )}
      {isUpdateModalOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleUpdateModal}>
            X
          </div>
          <UpdateLogModal
            submitForm={editRecord}
            startDate={startDate}
            setStartDate={setStartDate}
            editableData={editableData}
            loading={loading}
          />
        </div>
      )}
      {isDeleteModalOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleDeleteModal}>
            X
          </div>
          <DeleteLogModal
            loading={loading}
            deleteRecord={deleteRecord}
            toggleModal={toggleDeleteModal}
          />
        </div>
      )}
    </>
  );
};

export default DailyLogs;
