import { Snackbar, Alert, AlertTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';

// function AlertFn(props: any) {
//   return <Alert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    // marginTop: theme.spacing(2),
  },
}));

const SuccessAlert = (props: any) => {
  // @ts-check
  const classes = useStyles();

  const handleClose = () => {
    props.setIsSuccessAlertActive(false);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={props.isSuccessAlertActive}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleClose}
          onClick={handleClose}
          variant={props.variant}
          severity={props.severity}
          style={{ fontFamily: 'Work Sans, sans-serif' }}
        >
          <AlertTitle style={{ fontFamily: 'Work Sans, sans-serif' }}>
            {props.title}
          </AlertTitle>
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SuccessAlert;
