import React, { useState } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Formik } from 'formik';
import { CircularProgress } from '@mui/material';

// Styles
import '../../constants/theme.css';
import '../Login/Login.css';
import styles from './ForgotPassword.module.css';

// Images
import brandLogo from '../../assets/SMV_logo_white.png';

// Mutations
import { useForgotPasswordMutation } from '../../generated/graphql';

// Validation schema
import { forgotPasswordValidationSchema } from './validationSchema';

// Components
import { ErrorAlert, SuccessAlert } from '../../components';

interface FormValues {
  email: string;
}

const ForgotPassword: React.FC<RouteComponentProps> = ({ history }) => {
  // const [loading, setLoading] = useState<any>(false);
  const [isErrorAlertActive, setIsErrorAlertActive] = useState<Boolean>(false);
  const [isSuccessAlertActive, setIsSuccessAlertActive] =
    useState<Boolean>(false);
  const [forgotPassword, { loading, error }] = useForgotPasswordMutation();

  const navToLogin = () => {
    history.push('/login');
  };

  const submitForm = async (values: FormValues, resetForm: any) => {
    // setLoading(true);

    let email = values.email.toLowerCase();

    try {
      const response: any = await forgotPassword({
        variables: {
          email,
        },
      });

      if (response && response.data) {
        resetForm();

        setIsSuccessAlertActive(true);

        setTimeout(navToLogin, 5000);
      }
    } catch (error) {
      setIsErrorAlertActive(true);
    } finally {
      // setLoading(false);
    }
  };

  const navToHome = () => {
    history.push('/');
  };

  return (
    <div className="login">
      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request Failed"
        message={
          error
            ? error.graphQLErrors[0]?.message
            : 'Could not find a user with this email!'
        }
      />

      <SuccessAlert
        isSuccessAlertActive={isSuccessAlertActive}
        setIsSuccessAlertActive={setIsSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your password reset Link has been sent!"
      />
      <div className="form-con">
        <div className="app-logo" onClick={navToHome}>
          <img src={brandLogo} alt="Brand Logo" />
        </div>
        <Formik
          initialValues={{ email: '' }}
          validateOnMount={true}
          validationSchema={forgotPasswordValidationSchema}
          onSubmit={(values, { resetForm }) => submitForm(values, resetForm)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <h2>PASSWORD RECOVERY</h2>
              <input
                type="email"
                name="email"
                placeholder="Enter your email address"
                onChange={props.handleChange('email')}
                onBlur={props.handleBlur('email')}
                value={props.values.email.trim()}
              />
              {props.errors.email && props.touched.email && (
                <p className="errors">{props.errors.email}</p>
              )}

              <button type="submit" disabled={loading!}>
                {loading ? (
                  <CircularProgress style={{ color: '#fff' }} size={25} />
                ) : (
                  'Request reset'
                )}
              </button>
            </form>
          )}
        </Formik>
        <Link
          to="/login"
          style={{
            textDecoration: 'none',
            color: '#fff',
            marginTop: '1em',
            cursor: 'pointer',
          }}
        >
          <div>
            <span style={{ color: '#ffffffcc' }}>
              Remembered your password?
            </span>{' '}
            <span className={styles.forgotPasswordText}>Login</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
