import styles from './TableFooter.module.css';

interface IProps {
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  totalRows: number;
  totalPages: number;
  rowsPerPage: number;
}

const TableFooter = (props: IProps) => {
  const { activePage, setActivePage, totalRows, totalPages, rowsPerPage } =
    props;
  const beginning = activePage === 1 ? 1 : rowsPerPage * (activePage - 1) + 1;
  const end =
    activePage === totalPages ? totalRows : beginning + rowsPerPage - 1;

  return (
    <div className={styles.container}>
      <div className={styles.buttonWrapper}>
        <button disabled={activePage === 1} onClick={() => setActivePage(1)}>
          ⏮&nbsp;&nbsp;First
        </button>
        <button
          disabled={activePage === 1}
          onClick={() => setActivePage(activePage - 1)}
        >
          ⬅️&nbsp;&nbsp;Previous
        </button>
        <button
          disabled={activePage === totalPages}
          onClick={() => setActivePage(activePage + 1)}
        >
          Next&nbsp;&nbsp;➡️
        </button>
        <button
          disabled={activePage === totalPages}
          onClick={() => setActivePage(totalPages)}
        >
          Last&nbsp;&nbsp;⏭
        </button>
      </div>
      <p>
        Page {activePage} of {totalPages}
      </p>
      <p>
        Rows: {beginning === end ? end : `${beginning} - ${end}`} of {totalRows}
      </p>
    </div>
  );
};

export default TableFooter;
