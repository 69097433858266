import React from 'react';
import ReactDOM from 'react-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { store } from './app/store';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getAccessToken } from './utils/accessToken';
import { config } from './config';

const client: any = new ApolloClient({
  uri: config.APOLLO_CLIENT_URI,
  credentials: 'include',
  request: (operation) => {
    const accessToken = getAccessToken();

    if (accessToken) {
      operation.setContext({
        headers: {
          Authorization: `bearer ${accessToken}`,
        },
      });
    }
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
