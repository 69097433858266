import { Formik } from 'formik';

// Styles
import './Modal.css';

// Validation schema
import { updateClientModalValidationSchema } from './userValidationSchema';

// Loading spinner
import { CircularProgress } from '@mui/material';

const UpdateClientModal = (propss: any) => {
  return (
    <div className="main-modal-body">
      <Formik
        initialValues={{
          firstName: propss.editableData.first_name,
          lastName: propss.editableData.last_name,
          email: propss.editableData.email,
          phoneNumber: propss.editableData.phone_number,
          role: 'client',
        }}
        // validateOnMount={true}
        validationSchema={updateClientModalValidationSchema}
        onSubmit={(values, { resetForm }) => {
          propss.submitForm(values, resetForm);
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <input
              type="text"
              name="firstName"
              placeholder={propss.editableData.first_name}
              onChange={props.handleChange('firstName')}
              onBlur={props.handleBlur('firstName')}
              value={props.values.firstName}
            />
            {props.errors.firstName && props.touched.firstName && (
              <p className="validation-error">{props.errors.firstName}</p>
            )}

            <input
              type="text"
              name="lastName"
              placeholder={propss.editableData.last_name}
              onChange={props.handleChange('lastName')}
              onBlur={props.handleBlur('lastName')}
              value={props.values.lastName}
            />
            {props.errors.lastName && props.touched.lastName && (
              <p className="validation-error">{props.errors.lastName}</p>
            )}

            <input
              type="email"
              name="email"
              placeholder={propss.editableData.email}
              onChange={props.handleChange('email')}
              onBlur={props.handleBlur('email')}
              value={props.values.email.trim()}
            />
            {props.errors.email && props.touched.email && (
              <p className="validation-error">{props.errors.email}</p>
            )}

            <input
              type="text"
              name="phoneNumber"
              placeholder={propss.editableData.phone_number}
              onChange={props.handleChange('phoneNumber')}
              onBlur={props.handleBlur('phoneNumber')}
              value={props.values.phoneNumber}
            />
            {props.errors.phoneNumber && props.touched.phoneNumber && (
              <p className="validation-error">{props.errors.phoneNumber}</p>
            )}

            <button type="submit">
              {propss.loading ? (
                <CircularProgress style={{ color: '#fff' }} size={25} />
              ) : (
                'Update Client info'
              )}
            </button>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateClientModal;
