import NumberFormat from 'react-number-format';

// import React from 'react';
import './Overview.css';

// Icons
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useGetOneUserAndTheirAccountsQuery } from '../../generated/graphql';
import { Skeleton } from '@mui/material';

const ClientOverviewComponent = (props: any) => {
  const { userData } = props;
  const userId = userData?.id;

  const { data, loading } = useGetOneUserAndTheirAccountsQuery({
    variables: {
      id: userId,
    },
    fetchPolicy: 'cache-and-network',
  });

  let capitalAccBalance;
  let expensesAccBalance;
  let salesAccBalance;

  if (data) {
    data.findOneUserAndTheirAccounts.accounts.map((account) => {
      if (account.type === 'capital') {
        capitalAccBalance = account.balance;
      } else if (account.type === 'expenses') {
        expensesAccBalance = account.balance;
      } else {
        salesAccBalance = account.balance;
      }
    });
  }

  if (loading) {
    return (
      <div className="overview">
        <div className="overview-wrapper">
          {/* <div className="upper-card-con"> */}
          <Skeleton variant="rectangular" width={300} height={200} />

          <Skeleton variant="rectangular" width={300} height={200} />

          <Skeleton variant="rectangular" width={300} height={200} />
          {/* </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="overview-wrapper">
      {/* <div className="upper-card-con"> */}
      <div className="capital-acc-card">
        <h3 className="acc-title">Capital account</h3>
        <span className="acc-logo-bg">
          <AccountBalanceOutlinedIcon />
        </span>
        <h3 className="acct-balance">
          <NumberFormat
            value={capitalAccBalance}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₦'}
          />
        </h3>
      </div>

      <div className="expenses-acc-card">
        <h3 className="acc-title">Expenses account</h3>
        <span className="acc-logo-bg">
          <PaymentOutlinedIcon />
        </span>
        <h3 className="acct-balance">
          <NumberFormat
            value={expensesAccBalance}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₦'}
          />
        </h3>
      </div>

      <div className="sales-acc-card">
        <h3 className="acc-title">Sales account</h3>
        <span className="acc-logo-bg">
          <AccountBalanceWalletOutlinedIcon />
        </span>
        <h3 className="acct-balance">
          <NumberFormat
            value={salesAccBalance}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'₦'}
          />
        </h3>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ClientOverviewComponent;
