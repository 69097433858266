// @ts-ignore
// @ts-nocheck

// Styles
import './MainView.css';

//Hooks
import { useTypedSelector } from '../../app/hooks';
import {
  Overview,
  Expenses,
  Sales,
  Capital,
  Admin,
  Customers,
  Transactions,
  Settings,
  CapitalRecords,
  SalesRecords,
  DrugRecords,
  FeedRecords,
  AnimalRecords,
  MaintenanceRecords,
  MiscellaneousRecords,
  SalaryRecords,
  Staff,
  Client,
  Logs,
  DailyLogs,
} from '..';

const MainView = () => {
  const {
    stateName,
    overview,
    expenses,
    sales,
    transactions,
    capital,
    admin,
    customers,
    settings,
    capitalRecords,
    salesRecords,
    animalRecords,
    drugRecords,
    feedRecords,
    maintenanceRecords,
    miscellaneousRecords,
    salaryRecords,
    staff,
    client,
    logs,
    dailyLogs,
  } = useTypedSelector((state) => state.appState);

  const userData: any = JSON.parse(localStorage.getItem('userData'));

  return (
    <div className="main-view">
      <header className="main-view-header">
        <div className="main-view-header-wrapper">
          <h3>{stateName}</h3>
          <div className="main-view-header-user">
            <span>Welcome,</span>
            <h3>{userData?.first_name}</h3>
          </div>
        </div>
      </header>

      <div className="main-view-body">
        {overview && <Overview />}
        {capital && <Capital />}
        {expenses && <Expenses />}
        {sales && <Sales />}
        {transactions && <Transactions />}
        {admin && <Admin />}
        {customers && <Customers />}
        {settings && <Settings />}
        {capitalRecords && <CapitalRecords />}
        {salesRecords && <SalesRecords />}
        {animalRecords && <AnimalRecords />}
        {drugRecords && <DrugRecords />}
        {feedRecords && <FeedRecords />}
        {maintenanceRecords && <MaintenanceRecords />}
        {miscellaneousRecords && <MiscellaneousRecords />}
        {salaryRecords && <SalaryRecords />}
        {staff && <Staff />}
        {client && <Client />}
        {logs && <Logs />}
        {dailyLogs && <DailyLogs />}
      </div>
    </div>
  );
};

export default MainView;
