import { useEffect, useState } from 'react';

const sliceData = (data: any[], activePage: number, rowsPerPage: number) => {
  return data.slice((activePage - 1) * rowsPerPage, activePage * rowsPerPage);
};

export const useTable = (
  data: any[],
  activePage: number,
  rowsPerPage: number
) => {
  const [calculatedRows, setCalculatedRows] = useState<any[]>([]);
  const totalRows = data.length;
  const totalPages = Math.ceil(data.length / rowsPerPage);

  useEffect(() => {
    const slicedData = sliceData(data, activePage, rowsPerPage);

    setCalculatedRows([...slicedData]);
  }, [data, activePage, rowsPerPage, setCalculatedRows]);

  return { calculatedRows, totalRows, totalPages };
};
