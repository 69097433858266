import styles from './InputComponent.module.css';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const InputComponent = (props: any) => {
  const {
    type,
    name,
    placeholder,
    onChange,
    onBlur,
    value,
    isPasswordHidden,
    togglePasswordVisibility,
  } = props;

  const typeValue = type === 'password' && isPasswordHidden ? 'text' : type;

  return (
    <div className={styles.container}>
      <input
        type={typeValue}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      {type === 'password' ? (
        isPasswordHidden ? (
          <span onClick={togglePasswordVisibility}>
            <VisibilityOffOutlinedIcon
              sx={{ fontSize: 20, color: '#1e1b47' }}
            />
          </span>
        ) : (
          <span onClick={togglePasswordVisibility}>
            <VisibilityOutlinedIcon sx={{ fontSize: 20, color: '#1e1b47' }} />
          </span>
        )
      ) : null}
    </div>
  );
};

export default InputComponent;
