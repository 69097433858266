import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Account = {
  __typename?: 'Account';
  balance: Scalars['Float'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type Capital = {
  __typename?: 'Capital';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type Expenses = {
  __typename?: 'Expenses';
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  quantity: Scalars['Float'];
  total_amount: Scalars['Float'];
  value: Scalars['Float'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  access_token: Scalars['String'];
  user: User;
};

export type Logs = {
  __typename?: 'Logs';
  createdAt: Scalars['DateTime'];
  date: Scalars['String'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createAccount: Scalars['Boolean'];
  createCapitalRecord: Scalars['Boolean'];
  createExpenseRecord: Scalars['Boolean'];
  createLog: Scalars['Boolean'];
  createSalesRecord: Scalars['Boolean'];
  deleteAccountByType: Scalars['Boolean'];
  deleteCapitalRecordById: Scalars['Boolean'];
  deleteExpenseRecordById: Scalars['Boolean'];
  deleteLogById: Scalars['Boolean'];
  deleteSalesRecordById: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  login: LoginResponse;
  logout: Scalars['Boolean'];
  register: Scalars['Boolean'];
  resetAccounts: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  revokeRefreshToken: Scalars['Boolean'];
  updateCapitalRecordById: Scalars['Boolean'];
  updateExpenseRecordById: Scalars['Boolean'];
  updateLogById: Scalars['Boolean'];
  updateSalesRecordById: Scalars['Boolean'];
  updateUserById: Scalars['Boolean'];
  updateUserPassword: Scalars['Boolean'];
};


export type MutationCreateAccountArgs = {
  type: Scalars['String'];
  user: Scalars['String'];
};


export type MutationCreateCapitalRecordArgs = {
  amount: Scalars['Float'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  user: Scalars['String'];
};


export type MutationCreateExpenseRecordArgs = {
  category: Scalars['String'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  quantity: Scalars['Float'];
  user: Scalars['String'];
  value: Scalars['Float'];
};


export type MutationCreateLogArgs = {
  date: Scalars['String'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  user: Scalars['String'];
};


export type MutationCreateSalesRecordArgs = {
  category: Scalars['String'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  quantity: Scalars['Float'];
  user: Scalars['String'];
  value: Scalars['Float'];
};


export type MutationDeleteAccountByTypeArgs = {
  type: Scalars['String'];
  user: Scalars['String'];
};


export type MutationDeleteCapitalRecordByIdArgs = {
  id: Scalars['String'];
  user: Scalars['String'];
};


export type MutationDeleteExpenseRecordByIdArgs = {
  id: Scalars['String'];
  user: Scalars['String'];
};


export type MutationDeleteLogByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteSalesRecordByIdArgs = {
  id: Scalars['String'];
  user: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  password: Scalars['String'];
  phone_number: Scalars['String'];
  role: Scalars['String'];
  salary: Scalars['Float'];
};


export type MutationResetAccountsArgs = {
  user: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  new_password: Scalars['String'];
  new_password_confirmation: Scalars['String'];
  reset_password_token: Scalars['String'];
};


export type MutationRevokeRefreshTokenArgs = {
  id: Scalars['String'];
};


export type MutationUpdateCapitalRecordByIdArgs = {
  amount: Scalars['Float'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  user: Scalars['String'];
};


export type MutationUpdateExpenseRecordByIdArgs = {
  category: Scalars['String'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  quantity: Scalars['Float'];
  user: Scalars['String'];
  value: Scalars['Float'];
};


export type MutationUpdateLogByIdArgs = {
  date: Scalars['String'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpdateSalesRecordByIdArgs = {
  category: Scalars['String'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  quantity: Scalars['Float'];
  user: Scalars['String'];
  value: Scalars['Float'];
};


export type MutationUpdateUserByIdArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  id: Scalars['String'];
  last_name: Scalars['String'];
  phone_number: Scalars['String'];
  role: Scalars['String'];
  salary: Scalars['Float'];
};


export type MutationUpdateUserPasswordArgs = {
  current_password: Scalars['String'];
  id: Scalars['String'];
  new_password: Scalars['String'];
  new_password_confirmation: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  accounts: Array<Account>;
  bye: Scalars['String'];
  findAllAnimalRecords: Array<Expenses>;
  findAllCapitalRecords: Array<Capital>;
  findAllClient: Array<User>;
  findAllDrugRecords: Array<Expenses>;
  findAllExpenseRecords: Array<Expenses>;
  findAllFeedRecords: Array<Expenses>;
  findAllLogs: Array<Logs>;
  findAllMaintenanceRecords: Array<Expenses>;
  findAllMiscellaneousRecords: Array<Expenses>;
  findAllSalaryRecords: Array<Expenses>;
  findAllSalesRecords: Array<Sales>;
  findAllStaff: Array<User>;
  findOneUserAndTheirAccounts: User;
  getProfitMargin: Scalars['Float'];
  hello: Scalars['String'];
  users: Array<User>;
};


export type QueryFindAllAnimalRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllCapitalRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllDrugRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllExpenseRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllFeedRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllLogsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllMaintenanceRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllMiscellaneousRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllSalaryRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindAllSalesRecordsArgs = {
  user: Scalars['String'];
};


export type QueryFindOneUserAndTheirAccountsArgs = {
  id: Scalars['String'];
};


export type QueryGetProfitMarginArgs = {
  user: Scalars['String'];
};

export type Sales = {
  __typename?: 'Sales';
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  quantity: Scalars['Float'];
  total_amount: Scalars['Float'];
  value: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  accounts: Array<Account>;
  capital: Array<Capital>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  expenses: Array<Expenses>;
  first_name: Scalars['String'];
  id: Scalars['String'];
  last_name: Scalars['String'];
  phone_number: Scalars['String'];
  role: Scalars['String'];
  salary: Scalars['Float'];
  sales: Array<Sales>;
  token_version: Scalars['Float'];
};

export type CreateAccountMutationVariables = Exact<{
  type: Scalars['String'];
  user: Scalars['String'];
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount: boolean };

export type CreateCapitalRecordMutationVariables = Exact<{
  description: Scalars['String'];
  amount: Scalars['Float'];
  user: Scalars['String'];
  date_iso_string: Scalars['String'];
}>;


export type CreateCapitalRecordMutation = { __typename?: 'Mutation', createCapitalRecord: boolean };

export type CreateExpenseRecordMutationVariables = Exact<{
  category: Scalars['String'];
  description: Scalars['String'];
  quantity: Scalars['Float'];
  value: Scalars['Float'];
  user: Scalars['String'];
  date_iso_string: Scalars['String'];
}>;


export type CreateExpenseRecordMutation = { __typename?: 'Mutation', createExpenseRecord: boolean };

export type CreateLogMutationVariables = Exact<{
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  user: Scalars['String'];
  date: Scalars['String'];
}>;


export type CreateLogMutation = { __typename?: 'Mutation', createLog: boolean };

export type CreateSalesRecordMutationVariables = Exact<{
  category: Scalars['String'];
  description: Scalars['String'];
  quantity: Scalars['Float'];
  value: Scalars['Float'];
  user: Scalars['String'];
  date_iso_string: Scalars['String'];
}>;


export type CreateSalesRecordMutation = { __typename?: 'Mutation', createSalesRecord: boolean };

export type DeleteCapitalRecordMutationVariables = Exact<{
  deleteCapitalRecordByIdId: Scalars['String'];
  user: Scalars['String'];
}>;


export type DeleteCapitalRecordMutation = { __typename?: 'Mutation', deleteCapitalRecordById: boolean };

export type DeleteExpenseRecordMutationVariables = Exact<{
  deleteExpenseRecordByIdId: Scalars['String'];
  user: Scalars['String'];
}>;


export type DeleteExpenseRecordMutation = { __typename?: 'Mutation', deleteExpenseRecordById: boolean };

export type DeleteLogByIdMutationVariables = Exact<{
  deleteLogByIdId: Scalars['String'];
}>;


export type DeleteLogByIdMutation = { __typename?: 'Mutation', deleteLogById: boolean };

export type DeleteSalesRecordMutationVariables = Exact<{
  deleteSalesRecordByIdId: Scalars['String'];
  user: Scalars['String'];
}>;


export type DeleteSalesRecordMutation = { __typename?: 'Mutation', deleteSalesRecordById: boolean };

export type DeleteUserMutationVariables = Exact<{
  deleteUserId: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type GetAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountsQuery = { __typename?: 'Query', accounts: Array<{ __typename?: 'Account', id: string, type: string, balance: number }> };

export type GetAnimalRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetAnimalRecordsQuery = { __typename?: 'Query', findAllAnimalRecords: Array<{ __typename?: 'Expenses', id: string, category: string, description: string, quantity: number, value: number, total_amount: number, date_iso_string: string, createdAt: any }> };

export type GetCapitalRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetCapitalRecordsQuery = { __typename?: 'Query', findAllCapitalRecords: Array<{ __typename?: 'Capital', id: string, description: string, amount: number, date_iso_string: string, createdAt: any }> };

export type GetClientQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientQuery = { __typename?: 'Query', findAllClient: Array<{ __typename?: 'User', id: string, first_name: string, last_name: string, email: string, phone_number: string, role: string, createdAt: any }> };

export type GetDrugRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetDrugRecordsQuery = { __typename?: 'Query', findAllDrugRecords: Array<{ __typename?: 'Expenses', id: string, category: string, description: string, quantity: number, value: number, total_amount: number, date_iso_string: string, createdAt: any }> };

export type GetExpenseRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetExpenseRecordsQuery = { __typename?: 'Query', findAllExpenseRecords: Array<{ __typename?: 'Expenses', id: string, category: string, description: string, quantity: number, value: number, total_amount: number, date_iso_string: string, createdAt: any }> };

export type GetFeedRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetFeedRecordsQuery = { __typename?: 'Query', findAllFeedRecords: Array<{ __typename?: 'Expenses', id: string, category: string, description: string, quantity: number, value: number, total_amount: number, date_iso_string: string, createdAt: any }> };

export type GetLogsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetLogsQuery = { __typename?: 'Query', findAllLogs: Array<{ __typename?: 'Logs', id: string, date: string, description: string, date_iso_string: string, createdAt: any }> };

export type GetMaintenanceRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetMaintenanceRecordsQuery = { __typename?: 'Query', findAllMaintenanceRecords: Array<{ __typename?: 'Expenses', id: string, category: string, description: string, quantity: number, value: number, total_amount: number, date_iso_string: string, createdAt: any }> };

export type GetMiscellaneousRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetMiscellaneousRecordsQuery = { __typename?: 'Query', findAllMiscellaneousRecords: Array<{ __typename?: 'Expenses', id: string, category: string, description: string, quantity: number, value: number, total_amount: number, date_iso_string: string, createdAt: any }> };

export type GetOneUserAndTheirAccountsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetOneUserAndTheirAccountsQuery = { __typename?: 'Query', findOneUserAndTheirAccounts: { __typename?: 'User', id: string, first_name: string, last_name: string, email: string, phone_number: string, salary: number, role: string, createdAt: any, accounts: Array<{ __typename?: 'Account', id: string, type: string, balance: number }> } };

export type GetProfitMarginQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetProfitMarginQuery = { __typename?: 'Query', getProfitMargin: number };

export type GetSalaryRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetSalaryRecordsQuery = { __typename?: 'Query', findAllSalaryRecords: Array<{ __typename?: 'Expenses', id: string, category: string, description: string, quantity: number, value: number, total_amount: number, date_iso_string: string, createdAt: any }> };

export type GetSalesRecordsQueryVariables = Exact<{
  user: Scalars['String'];
}>;


export type GetSalesRecordsQuery = { __typename?: 'Query', findAllSalesRecords: Array<{ __typename?: 'Sales', id: string, category: string, description: string, quantity: number, value: number, total_amount: number, date_iso_string: string, createdAt: any }> };

export type GetStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStaffQuery = { __typename?: 'Query', findAllStaff: Array<{ __typename?: 'User', id: string, first_name: string, last_name: string, email: string, phone_number: string, salary: number, role: string, createdAt: any }> };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, first_name: string, last_name: string, email: string, phone_number: string, salary: number, role: string, createdAt: any, accounts: Array<{ __typename?: 'Account', id: string, type: string, balance: number }> }> };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginResponse', access_token: string, user: { __typename?: 'User', id: string, first_name: string, last_name: string, email: string, phone_number: string, salary: number, role: string, createdAt: any } } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type RegisterMutationVariables = Exact<{
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  salary: Scalars['Float'];
  role: Scalars['String'];
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: boolean };

export type ResetAccountsMutationMutationVariables = Exact<{
  user: Scalars['String'];
}>;


export type ResetAccountsMutationMutation = { __typename?: 'Mutation', resetAccounts: boolean };

export type UpdateCapitalRecordMutationVariables = Exact<{
  updateCapitalRecordByIdId: Scalars['String'];
  description: Scalars['String'];
  amount: Scalars['Float'];
  user: Scalars['String'];
  date_iso_string: Scalars['String'];
}>;


export type UpdateCapitalRecordMutation = { __typename?: 'Mutation', updateCapitalRecordById: boolean };

export type UpdateExpenseRecordMutationVariables = Exact<{
  updateExpenseRecordByIdId: Scalars['String'];
  category: Scalars['String'];
  description: Scalars['String'];
  quantity: Scalars['Float'];
  value: Scalars['Float'];
  user: Scalars['String'];
  date_iso_string: Scalars['String'];
}>;


export type UpdateExpenseRecordMutation = { __typename?: 'Mutation', updateExpenseRecordById: boolean };

export type UpdateLogByIdMutationVariables = Exact<{
  date_iso_string: Scalars['String'];
  description: Scalars['String'];
  date: Scalars['String'];
  updateLogByIdId: Scalars['String'];
}>;


export type UpdateLogByIdMutation = { __typename?: 'Mutation', updateLogById: boolean };

export type UpdateSalesRecordMutationVariables = Exact<{
  updateSalesRecordByIdId: Scalars['String'];
  category: Scalars['String'];
  description: Scalars['String'];
  quantity: Scalars['Float'];
  value: Scalars['Float'];
  user: Scalars['String'];
  date_iso_string: Scalars['String'];
}>;


export type UpdateSalesRecordMutation = { __typename?: 'Mutation', updateSalesRecordById: boolean };

export type UpdateUserMutationVariables = Exact<{
  updateUserByIdId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  salary: Scalars['Float'];
  role: Scalars['String'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUserById: boolean };

export type UpdateUserPasswordMutationVariables = Exact<{
  updateUserPasswordId: Scalars['String'];
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirmation: Scalars['String'];
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', updateUserPassword: boolean };


export const CreateAccountDocument = gql`
    mutation createAccount($type: String!, $user: String!) {
  createAccount(type: $type, user: $user)
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      type: // value for 'type'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const CreateCapitalRecordDocument = gql`
    mutation createCapitalRecord($description: String!, $amount: Float!, $user: String!, $date_iso_string: String!) {
  createCapitalRecord(
    description: $description
    amount: $amount
    user: $user
    date_iso_string: $date_iso_string
  )
}
    `;
export type CreateCapitalRecordMutationFn = Apollo.MutationFunction<CreateCapitalRecordMutation, CreateCapitalRecordMutationVariables>;

/**
 * __useCreateCapitalRecordMutation__
 *
 * To run a mutation, you first call `useCreateCapitalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCapitalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCapitalRecordMutation, { data, loading, error }] = useCreateCapitalRecordMutation({
 *   variables: {
 *      description: // value for 'description'
 *      amount: // value for 'amount'
 *      user: // value for 'user'
 *      date_iso_string: // value for 'date_iso_string'
 *   },
 * });
 */
export function useCreateCapitalRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateCapitalRecordMutation, CreateCapitalRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCapitalRecordMutation, CreateCapitalRecordMutationVariables>(CreateCapitalRecordDocument, options);
      }
export type CreateCapitalRecordMutationHookResult = ReturnType<typeof useCreateCapitalRecordMutation>;
export type CreateCapitalRecordMutationResult = Apollo.MutationResult<CreateCapitalRecordMutation>;
export type CreateCapitalRecordMutationOptions = Apollo.BaseMutationOptions<CreateCapitalRecordMutation, CreateCapitalRecordMutationVariables>;
export const CreateExpenseRecordDocument = gql`
    mutation createExpenseRecord($category: String!, $description: String!, $quantity: Float!, $value: Float!, $user: String!, $date_iso_string: String!) {
  createExpenseRecord(
    category: $category
    description: $description
    quantity: $quantity
    value: $value
    user: $user
    date_iso_string: $date_iso_string
  )
}
    `;
export type CreateExpenseRecordMutationFn = Apollo.MutationFunction<CreateExpenseRecordMutation, CreateExpenseRecordMutationVariables>;

/**
 * __useCreateExpenseRecordMutation__
 *
 * To run a mutation, you first call `useCreateExpenseRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseRecordMutation, { data, loading, error }] = useCreateExpenseRecordMutation({
 *   variables: {
 *      category: // value for 'category'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      value: // value for 'value'
 *      user: // value for 'user'
 *      date_iso_string: // value for 'date_iso_string'
 *   },
 * });
 */
export function useCreateExpenseRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseRecordMutation, CreateExpenseRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseRecordMutation, CreateExpenseRecordMutationVariables>(CreateExpenseRecordDocument, options);
      }
export type CreateExpenseRecordMutationHookResult = ReturnType<typeof useCreateExpenseRecordMutation>;
export type CreateExpenseRecordMutationResult = Apollo.MutationResult<CreateExpenseRecordMutation>;
export type CreateExpenseRecordMutationOptions = Apollo.BaseMutationOptions<CreateExpenseRecordMutation, CreateExpenseRecordMutationVariables>;
export const CreateLogDocument = gql`
    mutation CreateLog($date_iso_string: String!, $description: String!, $user: String!, $date: String!) {
  createLog(
    date_iso_string: $date_iso_string
    description: $description
    user: $user
    date: $date
  )
}
    `;
export type CreateLogMutationFn = Apollo.MutationFunction<CreateLogMutation, CreateLogMutationVariables>;

/**
 * __useCreateLogMutation__
 *
 * To run a mutation, you first call `useCreateLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLogMutation, { data, loading, error }] = useCreateLogMutation({
 *   variables: {
 *      date_iso_string: // value for 'date_iso_string'
 *      description: // value for 'description'
 *      user: // value for 'user'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useCreateLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateLogMutation, CreateLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLogMutation, CreateLogMutationVariables>(CreateLogDocument, options);
      }
export type CreateLogMutationHookResult = ReturnType<typeof useCreateLogMutation>;
export type CreateLogMutationResult = Apollo.MutationResult<CreateLogMutation>;
export type CreateLogMutationOptions = Apollo.BaseMutationOptions<CreateLogMutation, CreateLogMutationVariables>;
export const CreateSalesRecordDocument = gql`
    mutation createSalesRecord($category: String!, $description: String!, $quantity: Float!, $value: Float!, $user: String!, $date_iso_string: String!) {
  createSalesRecord(
    category: $category
    description: $description
    quantity: $quantity
    value: $value
    user: $user
    date_iso_string: $date_iso_string
  )
}
    `;
export type CreateSalesRecordMutationFn = Apollo.MutationFunction<CreateSalesRecordMutation, CreateSalesRecordMutationVariables>;

/**
 * __useCreateSalesRecordMutation__
 *
 * To run a mutation, you first call `useCreateSalesRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSalesRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSalesRecordMutation, { data, loading, error }] = useCreateSalesRecordMutation({
 *   variables: {
 *      category: // value for 'category'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      value: // value for 'value'
 *      user: // value for 'user'
 *      date_iso_string: // value for 'date_iso_string'
 *   },
 * });
 */
export function useCreateSalesRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalesRecordMutation, CreateSalesRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSalesRecordMutation, CreateSalesRecordMutationVariables>(CreateSalesRecordDocument, options);
      }
export type CreateSalesRecordMutationHookResult = ReturnType<typeof useCreateSalesRecordMutation>;
export type CreateSalesRecordMutationResult = Apollo.MutationResult<CreateSalesRecordMutation>;
export type CreateSalesRecordMutationOptions = Apollo.BaseMutationOptions<CreateSalesRecordMutation, CreateSalesRecordMutationVariables>;
export const DeleteCapitalRecordDocument = gql`
    mutation deleteCapitalRecord($deleteCapitalRecordByIdId: String!, $user: String!) {
  deleteCapitalRecordById(id: $deleteCapitalRecordByIdId, user: $user)
}
    `;
export type DeleteCapitalRecordMutationFn = Apollo.MutationFunction<DeleteCapitalRecordMutation, DeleteCapitalRecordMutationVariables>;

/**
 * __useDeleteCapitalRecordMutation__
 *
 * To run a mutation, you first call `useDeleteCapitalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCapitalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCapitalRecordMutation, { data, loading, error }] = useDeleteCapitalRecordMutation({
 *   variables: {
 *      deleteCapitalRecordByIdId: // value for 'deleteCapitalRecordByIdId'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useDeleteCapitalRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCapitalRecordMutation, DeleteCapitalRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCapitalRecordMutation, DeleteCapitalRecordMutationVariables>(DeleteCapitalRecordDocument, options);
      }
export type DeleteCapitalRecordMutationHookResult = ReturnType<typeof useDeleteCapitalRecordMutation>;
export type DeleteCapitalRecordMutationResult = Apollo.MutationResult<DeleteCapitalRecordMutation>;
export type DeleteCapitalRecordMutationOptions = Apollo.BaseMutationOptions<DeleteCapitalRecordMutation, DeleteCapitalRecordMutationVariables>;
export const DeleteExpenseRecordDocument = gql`
    mutation deleteExpenseRecord($deleteExpenseRecordByIdId: String!, $user: String!) {
  deleteExpenseRecordById(id: $deleteExpenseRecordByIdId, user: $user)
}
    `;
export type DeleteExpenseRecordMutationFn = Apollo.MutationFunction<DeleteExpenseRecordMutation, DeleteExpenseRecordMutationVariables>;

/**
 * __useDeleteExpenseRecordMutation__
 *
 * To run a mutation, you first call `useDeleteExpenseRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExpenseRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExpenseRecordMutation, { data, loading, error }] = useDeleteExpenseRecordMutation({
 *   variables: {
 *      deleteExpenseRecordByIdId: // value for 'deleteExpenseRecordByIdId'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useDeleteExpenseRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExpenseRecordMutation, DeleteExpenseRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExpenseRecordMutation, DeleteExpenseRecordMutationVariables>(DeleteExpenseRecordDocument, options);
      }
export type DeleteExpenseRecordMutationHookResult = ReturnType<typeof useDeleteExpenseRecordMutation>;
export type DeleteExpenseRecordMutationResult = Apollo.MutationResult<DeleteExpenseRecordMutation>;
export type DeleteExpenseRecordMutationOptions = Apollo.BaseMutationOptions<DeleteExpenseRecordMutation, DeleteExpenseRecordMutationVariables>;
export const DeleteLogByIdDocument = gql`
    mutation DeleteLogById($deleteLogByIdId: String!) {
  deleteLogById(id: $deleteLogByIdId)
}
    `;
export type DeleteLogByIdMutationFn = Apollo.MutationFunction<DeleteLogByIdMutation, DeleteLogByIdMutationVariables>;

/**
 * __useDeleteLogByIdMutation__
 *
 * To run a mutation, you first call `useDeleteLogByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogByIdMutation, { data, loading, error }] = useDeleteLogByIdMutation({
 *   variables: {
 *      deleteLogByIdId: // value for 'deleteLogByIdId'
 *   },
 * });
 */
export function useDeleteLogByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLogByIdMutation, DeleteLogByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLogByIdMutation, DeleteLogByIdMutationVariables>(DeleteLogByIdDocument, options);
      }
export type DeleteLogByIdMutationHookResult = ReturnType<typeof useDeleteLogByIdMutation>;
export type DeleteLogByIdMutationResult = Apollo.MutationResult<DeleteLogByIdMutation>;
export type DeleteLogByIdMutationOptions = Apollo.BaseMutationOptions<DeleteLogByIdMutation, DeleteLogByIdMutationVariables>;
export const DeleteSalesRecordDocument = gql`
    mutation deleteSalesRecord($deleteSalesRecordByIdId: String!, $user: String!) {
  deleteSalesRecordById(id: $deleteSalesRecordByIdId, user: $user)
}
    `;
export type DeleteSalesRecordMutationFn = Apollo.MutationFunction<DeleteSalesRecordMutation, DeleteSalesRecordMutationVariables>;

/**
 * __useDeleteSalesRecordMutation__
 *
 * To run a mutation, you first call `useDeleteSalesRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSalesRecordMutation, { data, loading, error }] = useDeleteSalesRecordMutation({
 *   variables: {
 *      deleteSalesRecordByIdId: // value for 'deleteSalesRecordByIdId'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useDeleteSalesRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalesRecordMutation, DeleteSalesRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSalesRecordMutation, DeleteSalesRecordMutationVariables>(DeleteSalesRecordDocument, options);
      }
export type DeleteSalesRecordMutationHookResult = ReturnType<typeof useDeleteSalesRecordMutation>;
export type DeleteSalesRecordMutationResult = Apollo.MutationResult<DeleteSalesRecordMutation>;
export type DeleteSalesRecordMutationOptions = Apollo.BaseMutationOptions<DeleteSalesRecordMutation, DeleteSalesRecordMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($deleteUserId: String!) {
  deleteUser(id: $deleteUserId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserId: // value for 'deleteUserId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GetAccountsDocument = gql`
    query getAccounts {
  accounts {
    id
    type
    balance
  }
}
    `;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, options);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const GetAnimalRecordsDocument = gql`
    query getAnimalRecords($user: String!) {
  findAllAnimalRecords(user: $user) {
    id
    category
    description
    quantity
    value
    total_amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetAnimalRecordsQuery__
 *
 * To run a query within a React component, call `useGetAnimalRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnimalRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnimalRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetAnimalRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetAnimalRecordsQuery, GetAnimalRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnimalRecordsQuery, GetAnimalRecordsQueryVariables>(GetAnimalRecordsDocument, options);
      }
export function useGetAnimalRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnimalRecordsQuery, GetAnimalRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnimalRecordsQuery, GetAnimalRecordsQueryVariables>(GetAnimalRecordsDocument, options);
        }
export type GetAnimalRecordsQueryHookResult = ReturnType<typeof useGetAnimalRecordsQuery>;
export type GetAnimalRecordsLazyQueryHookResult = ReturnType<typeof useGetAnimalRecordsLazyQuery>;
export type GetAnimalRecordsQueryResult = Apollo.QueryResult<GetAnimalRecordsQuery, GetAnimalRecordsQueryVariables>;
export const GetCapitalRecordsDocument = gql`
    query getCapitalRecords($user: String!) {
  findAllCapitalRecords(user: $user) {
    id
    description
    amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetCapitalRecordsQuery__
 *
 * To run a query within a React component, call `useGetCapitalRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapitalRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapitalRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetCapitalRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetCapitalRecordsQuery, GetCapitalRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCapitalRecordsQuery, GetCapitalRecordsQueryVariables>(GetCapitalRecordsDocument, options);
      }
export function useGetCapitalRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCapitalRecordsQuery, GetCapitalRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCapitalRecordsQuery, GetCapitalRecordsQueryVariables>(GetCapitalRecordsDocument, options);
        }
export type GetCapitalRecordsQueryHookResult = ReturnType<typeof useGetCapitalRecordsQuery>;
export type GetCapitalRecordsLazyQueryHookResult = ReturnType<typeof useGetCapitalRecordsLazyQuery>;
export type GetCapitalRecordsQueryResult = Apollo.QueryResult<GetCapitalRecordsQuery, GetCapitalRecordsQueryVariables>;
export const GetClientDocument = gql`
    query getClient {
  findAllClient {
    id
    first_name
    last_name
    email
    phone_number
    role
    createdAt
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientQuery(baseOptions?: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;
export const GetDrugRecordsDocument = gql`
    query getDrugRecords($user: String!) {
  findAllDrugRecords(user: $user) {
    id
    category
    description
    quantity
    value
    total_amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetDrugRecordsQuery__
 *
 * To run a query within a React component, call `useGetDrugRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDrugRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDrugRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetDrugRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetDrugRecordsQuery, GetDrugRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDrugRecordsQuery, GetDrugRecordsQueryVariables>(GetDrugRecordsDocument, options);
      }
export function useGetDrugRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDrugRecordsQuery, GetDrugRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDrugRecordsQuery, GetDrugRecordsQueryVariables>(GetDrugRecordsDocument, options);
        }
export type GetDrugRecordsQueryHookResult = ReturnType<typeof useGetDrugRecordsQuery>;
export type GetDrugRecordsLazyQueryHookResult = ReturnType<typeof useGetDrugRecordsLazyQuery>;
export type GetDrugRecordsQueryResult = Apollo.QueryResult<GetDrugRecordsQuery, GetDrugRecordsQueryVariables>;
export const GetExpenseRecordsDocument = gql`
    query getExpenseRecords($user: String!) {
  findAllExpenseRecords(user: $user) {
    id
    category
    description
    quantity
    value
    total_amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetExpenseRecordsQuery__
 *
 * To run a query within a React component, call `useGetExpenseRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpenseRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpenseRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetExpenseRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetExpenseRecordsQuery, GetExpenseRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExpenseRecordsQuery, GetExpenseRecordsQueryVariables>(GetExpenseRecordsDocument, options);
      }
export function useGetExpenseRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExpenseRecordsQuery, GetExpenseRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExpenseRecordsQuery, GetExpenseRecordsQueryVariables>(GetExpenseRecordsDocument, options);
        }
export type GetExpenseRecordsQueryHookResult = ReturnType<typeof useGetExpenseRecordsQuery>;
export type GetExpenseRecordsLazyQueryHookResult = ReturnType<typeof useGetExpenseRecordsLazyQuery>;
export type GetExpenseRecordsQueryResult = Apollo.QueryResult<GetExpenseRecordsQuery, GetExpenseRecordsQueryVariables>;
export const GetFeedRecordsDocument = gql`
    query getFeedRecords($user: String!) {
  findAllFeedRecords(user: $user) {
    id
    category
    description
    quantity
    value
    total_amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetFeedRecordsQuery__
 *
 * To run a query within a React component, call `useGetFeedRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetFeedRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetFeedRecordsQuery, GetFeedRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeedRecordsQuery, GetFeedRecordsQueryVariables>(GetFeedRecordsDocument, options);
      }
export function useGetFeedRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedRecordsQuery, GetFeedRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeedRecordsQuery, GetFeedRecordsQueryVariables>(GetFeedRecordsDocument, options);
        }
export type GetFeedRecordsQueryHookResult = ReturnType<typeof useGetFeedRecordsQuery>;
export type GetFeedRecordsLazyQueryHookResult = ReturnType<typeof useGetFeedRecordsLazyQuery>;
export type GetFeedRecordsQueryResult = Apollo.QueryResult<GetFeedRecordsQuery, GetFeedRecordsQueryVariables>;
export const GetLogsDocument = gql`
    query getLogs($user: String!) {
  findAllLogs(user: $user) {
    id
    date
    description
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetLogsQuery__
 *
 * To run a query within a React component, call `useGetLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetLogsQuery(baseOptions: Apollo.QueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
      }
export function useGetLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLogsQuery, GetLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLogsQuery, GetLogsQueryVariables>(GetLogsDocument, options);
        }
export type GetLogsQueryHookResult = ReturnType<typeof useGetLogsQuery>;
export type GetLogsLazyQueryHookResult = ReturnType<typeof useGetLogsLazyQuery>;
export type GetLogsQueryResult = Apollo.QueryResult<GetLogsQuery, GetLogsQueryVariables>;
export const GetMaintenanceRecordsDocument = gql`
    query getMaintenanceRecords($user: String!) {
  findAllMaintenanceRecords(user: $user) {
    id
    category
    description
    quantity
    value
    total_amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetMaintenanceRecordsQuery__
 *
 * To run a query within a React component, call `useGetMaintenanceRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMaintenanceRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMaintenanceRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetMaintenanceRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetMaintenanceRecordsQuery, GetMaintenanceRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMaintenanceRecordsQuery, GetMaintenanceRecordsQueryVariables>(GetMaintenanceRecordsDocument, options);
      }
export function useGetMaintenanceRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMaintenanceRecordsQuery, GetMaintenanceRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMaintenanceRecordsQuery, GetMaintenanceRecordsQueryVariables>(GetMaintenanceRecordsDocument, options);
        }
export type GetMaintenanceRecordsQueryHookResult = ReturnType<typeof useGetMaintenanceRecordsQuery>;
export type GetMaintenanceRecordsLazyQueryHookResult = ReturnType<typeof useGetMaintenanceRecordsLazyQuery>;
export type GetMaintenanceRecordsQueryResult = Apollo.QueryResult<GetMaintenanceRecordsQuery, GetMaintenanceRecordsQueryVariables>;
export const GetMiscellaneousRecordsDocument = gql`
    query getMiscellaneousRecords($user: String!) {
  findAllMiscellaneousRecords(user: $user) {
    id
    category
    description
    quantity
    value
    total_amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetMiscellaneousRecordsQuery__
 *
 * To run a query within a React component, call `useGetMiscellaneousRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiscellaneousRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiscellaneousRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetMiscellaneousRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetMiscellaneousRecordsQuery, GetMiscellaneousRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMiscellaneousRecordsQuery, GetMiscellaneousRecordsQueryVariables>(GetMiscellaneousRecordsDocument, options);
      }
export function useGetMiscellaneousRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMiscellaneousRecordsQuery, GetMiscellaneousRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMiscellaneousRecordsQuery, GetMiscellaneousRecordsQueryVariables>(GetMiscellaneousRecordsDocument, options);
        }
export type GetMiscellaneousRecordsQueryHookResult = ReturnType<typeof useGetMiscellaneousRecordsQuery>;
export type GetMiscellaneousRecordsLazyQueryHookResult = ReturnType<typeof useGetMiscellaneousRecordsLazyQuery>;
export type GetMiscellaneousRecordsQueryResult = Apollo.QueryResult<GetMiscellaneousRecordsQuery, GetMiscellaneousRecordsQueryVariables>;
export const GetOneUserAndTheirAccountsDocument = gql`
    query getOneUserAndTheirAccounts($id: String!) {
  findOneUserAndTheirAccounts(id: $id) {
    id
    first_name
    last_name
    email
    phone_number
    salary
    role
    createdAt
    accounts {
      id
      type
      balance
    }
  }
}
    `;

/**
 * __useGetOneUserAndTheirAccountsQuery__
 *
 * To run a query within a React component, call `useGetOneUserAndTheirAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneUserAndTheirAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneUserAndTheirAccountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneUserAndTheirAccountsQuery(baseOptions: Apollo.QueryHookOptions<GetOneUserAndTheirAccountsQuery, GetOneUserAndTheirAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneUserAndTheirAccountsQuery, GetOneUserAndTheirAccountsQueryVariables>(GetOneUserAndTheirAccountsDocument, options);
      }
export function useGetOneUserAndTheirAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneUserAndTheirAccountsQuery, GetOneUserAndTheirAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneUserAndTheirAccountsQuery, GetOneUserAndTheirAccountsQueryVariables>(GetOneUserAndTheirAccountsDocument, options);
        }
export type GetOneUserAndTheirAccountsQueryHookResult = ReturnType<typeof useGetOneUserAndTheirAccountsQuery>;
export type GetOneUserAndTheirAccountsLazyQueryHookResult = ReturnType<typeof useGetOneUserAndTheirAccountsLazyQuery>;
export type GetOneUserAndTheirAccountsQueryResult = Apollo.QueryResult<GetOneUserAndTheirAccountsQuery, GetOneUserAndTheirAccountsQueryVariables>;
export const GetProfitMarginDocument = gql`
    query getProfitMargin($user: String!) {
  getProfitMargin(user: $user)
}
    `;

/**
 * __useGetProfitMarginQuery__
 *
 * To run a query within a React component, call `useGetProfitMarginQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfitMarginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfitMarginQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetProfitMarginQuery(baseOptions: Apollo.QueryHookOptions<GetProfitMarginQuery, GetProfitMarginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfitMarginQuery, GetProfitMarginQueryVariables>(GetProfitMarginDocument, options);
      }
export function useGetProfitMarginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfitMarginQuery, GetProfitMarginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfitMarginQuery, GetProfitMarginQueryVariables>(GetProfitMarginDocument, options);
        }
export type GetProfitMarginQueryHookResult = ReturnType<typeof useGetProfitMarginQuery>;
export type GetProfitMarginLazyQueryHookResult = ReturnType<typeof useGetProfitMarginLazyQuery>;
export type GetProfitMarginQueryResult = Apollo.QueryResult<GetProfitMarginQuery, GetProfitMarginQueryVariables>;
export const GetSalaryRecordsDocument = gql`
    query getSalaryRecords($user: String!) {
  findAllSalaryRecords(user: $user) {
    id
    category
    description
    quantity
    value
    total_amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetSalaryRecordsQuery__
 *
 * To run a query within a React component, call `useGetSalaryRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalaryRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalaryRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetSalaryRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetSalaryRecordsQuery, GetSalaryRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalaryRecordsQuery, GetSalaryRecordsQueryVariables>(GetSalaryRecordsDocument, options);
      }
export function useGetSalaryRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalaryRecordsQuery, GetSalaryRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalaryRecordsQuery, GetSalaryRecordsQueryVariables>(GetSalaryRecordsDocument, options);
        }
export type GetSalaryRecordsQueryHookResult = ReturnType<typeof useGetSalaryRecordsQuery>;
export type GetSalaryRecordsLazyQueryHookResult = ReturnType<typeof useGetSalaryRecordsLazyQuery>;
export type GetSalaryRecordsQueryResult = Apollo.QueryResult<GetSalaryRecordsQuery, GetSalaryRecordsQueryVariables>;
export const GetSalesRecordsDocument = gql`
    query getSalesRecords($user: String!) {
  findAllSalesRecords(user: $user) {
    id
    category
    description
    quantity
    value
    total_amount
    date_iso_string
    createdAt
  }
}
    `;

/**
 * __useGetSalesRecordsQuery__
 *
 * To run a query within a React component, call `useGetSalesRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesRecordsQuery({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetSalesRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetSalesRecordsQuery, GetSalesRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesRecordsQuery, GetSalesRecordsQueryVariables>(GetSalesRecordsDocument, options);
      }
export function useGetSalesRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesRecordsQuery, GetSalesRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesRecordsQuery, GetSalesRecordsQueryVariables>(GetSalesRecordsDocument, options);
        }
export type GetSalesRecordsQueryHookResult = ReturnType<typeof useGetSalesRecordsQuery>;
export type GetSalesRecordsLazyQueryHookResult = ReturnType<typeof useGetSalesRecordsLazyQuery>;
export type GetSalesRecordsQueryResult = Apollo.QueryResult<GetSalesRecordsQuery, GetSalesRecordsQueryVariables>;
export const GetStaffDocument = gql`
    query getStaff {
  findAllStaff {
    id
    first_name
    last_name
    email
    phone_number
    salary
    role
    createdAt
  }
}
    `;

/**
 * __useGetStaffQuery__
 *
 * To run a query within a React component, call `useGetStaffQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStaffQuery(baseOptions?: Apollo.QueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
      }
export function useGetStaffLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaffQuery, GetStaffQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaffQuery, GetStaffQueryVariables>(GetStaffDocument, options);
        }
export type GetStaffQueryHookResult = ReturnType<typeof useGetStaffQuery>;
export type GetStaffLazyQueryHookResult = ReturnType<typeof useGetStaffLazyQuery>;
export type GetStaffQueryResult = Apollo.QueryResult<GetStaffQuery, GetStaffQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  users {
    id
    first_name
    last_name
    email
    phone_number
    salary
    role
    createdAt
    accounts {
      id
      type
      balance
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
      first_name
      last_name
      email
      phone_number
      salary
      role
      createdAt
    }
    access_token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($first_name: String!, $last_name: String!, $email: String!, $password: String!, $phoneNumber: String!, $salary: Float!, $role: String!) {
  register(
    first_name: $first_name
    last_name: $last_name
    email: $email
    password: $password
    phone_number: $phoneNumber
    salary: $salary
    role: $role
  )
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      phoneNumber: // value for 'phoneNumber'
 *      salary: // value for 'salary'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const ResetAccountsMutationDocument = gql`
    mutation ResetAccountsMutation($user: String!) {
  resetAccounts(user: $user)
}
    `;
export type ResetAccountsMutationMutationFn = Apollo.MutationFunction<ResetAccountsMutationMutation, ResetAccountsMutationMutationVariables>;

/**
 * __useResetAccountsMutationMutation__
 *
 * To run a mutation, you first call `useResetAccountsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAccountsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAccountsMutationMutation, { data, loading, error }] = useResetAccountsMutationMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useResetAccountsMutationMutation(baseOptions?: Apollo.MutationHookOptions<ResetAccountsMutationMutation, ResetAccountsMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetAccountsMutationMutation, ResetAccountsMutationMutationVariables>(ResetAccountsMutationDocument, options);
      }
export type ResetAccountsMutationMutationHookResult = ReturnType<typeof useResetAccountsMutationMutation>;
export type ResetAccountsMutationMutationResult = Apollo.MutationResult<ResetAccountsMutationMutation>;
export type ResetAccountsMutationMutationOptions = Apollo.BaseMutationOptions<ResetAccountsMutationMutation, ResetAccountsMutationMutationVariables>;
export const UpdateCapitalRecordDocument = gql`
    mutation updateCapitalRecord($updateCapitalRecordByIdId: String!, $description: String!, $amount: Float!, $user: String!, $date_iso_string: String!) {
  updateCapitalRecordById(
    id: $updateCapitalRecordByIdId
    description: $description
    amount: $amount
    user: $user
    date_iso_string: $date_iso_string
  )
}
    `;
export type UpdateCapitalRecordMutationFn = Apollo.MutationFunction<UpdateCapitalRecordMutation, UpdateCapitalRecordMutationVariables>;

/**
 * __useUpdateCapitalRecordMutation__
 *
 * To run a mutation, you first call `useUpdateCapitalRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCapitalRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCapitalRecordMutation, { data, loading, error }] = useUpdateCapitalRecordMutation({
 *   variables: {
 *      updateCapitalRecordByIdId: // value for 'updateCapitalRecordByIdId'
 *      description: // value for 'description'
 *      amount: // value for 'amount'
 *      user: // value for 'user'
 *      date_iso_string: // value for 'date_iso_string'
 *   },
 * });
 */
export function useUpdateCapitalRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCapitalRecordMutation, UpdateCapitalRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCapitalRecordMutation, UpdateCapitalRecordMutationVariables>(UpdateCapitalRecordDocument, options);
      }
export type UpdateCapitalRecordMutationHookResult = ReturnType<typeof useUpdateCapitalRecordMutation>;
export type UpdateCapitalRecordMutationResult = Apollo.MutationResult<UpdateCapitalRecordMutation>;
export type UpdateCapitalRecordMutationOptions = Apollo.BaseMutationOptions<UpdateCapitalRecordMutation, UpdateCapitalRecordMutationVariables>;
export const UpdateExpenseRecordDocument = gql`
    mutation updateExpenseRecord($updateExpenseRecordByIdId: String!, $category: String!, $description: String!, $quantity: Float!, $value: Float!, $user: String!, $date_iso_string: String!) {
  updateExpenseRecordById(
    id: $updateExpenseRecordByIdId
    category: $category
    description: $description
    quantity: $quantity
    value: $value
    user: $user
    date_iso_string: $date_iso_string
  )
}
    `;
export type UpdateExpenseRecordMutationFn = Apollo.MutationFunction<UpdateExpenseRecordMutation, UpdateExpenseRecordMutationVariables>;

/**
 * __useUpdateExpenseRecordMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseRecordMutation, { data, loading, error }] = useUpdateExpenseRecordMutation({
 *   variables: {
 *      updateExpenseRecordByIdId: // value for 'updateExpenseRecordByIdId'
 *      category: // value for 'category'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      value: // value for 'value'
 *      user: // value for 'user'
 *      date_iso_string: // value for 'date_iso_string'
 *   },
 * });
 */
export function useUpdateExpenseRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseRecordMutation, UpdateExpenseRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseRecordMutation, UpdateExpenseRecordMutationVariables>(UpdateExpenseRecordDocument, options);
      }
export type UpdateExpenseRecordMutationHookResult = ReturnType<typeof useUpdateExpenseRecordMutation>;
export type UpdateExpenseRecordMutationResult = Apollo.MutationResult<UpdateExpenseRecordMutation>;
export type UpdateExpenseRecordMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseRecordMutation, UpdateExpenseRecordMutationVariables>;
export const UpdateLogByIdDocument = gql`
    mutation UpdateLogById($date_iso_string: String!, $description: String!, $date: String!, $updateLogByIdId: String!) {
  updateLogById(
    date_iso_string: $date_iso_string
    description: $description
    date: $date
    id: $updateLogByIdId
  )
}
    `;
export type UpdateLogByIdMutationFn = Apollo.MutationFunction<UpdateLogByIdMutation, UpdateLogByIdMutationVariables>;

/**
 * __useUpdateLogByIdMutation__
 *
 * To run a mutation, you first call `useUpdateLogByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLogByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLogByIdMutation, { data, loading, error }] = useUpdateLogByIdMutation({
 *   variables: {
 *      date_iso_string: // value for 'date_iso_string'
 *      description: // value for 'description'
 *      date: // value for 'date'
 *      updateLogByIdId: // value for 'updateLogByIdId'
 *   },
 * });
 */
export function useUpdateLogByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLogByIdMutation, UpdateLogByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLogByIdMutation, UpdateLogByIdMutationVariables>(UpdateLogByIdDocument, options);
      }
export type UpdateLogByIdMutationHookResult = ReturnType<typeof useUpdateLogByIdMutation>;
export type UpdateLogByIdMutationResult = Apollo.MutationResult<UpdateLogByIdMutation>;
export type UpdateLogByIdMutationOptions = Apollo.BaseMutationOptions<UpdateLogByIdMutation, UpdateLogByIdMutationVariables>;
export const UpdateSalesRecordDocument = gql`
    mutation updateSalesRecord($updateSalesRecordByIdId: String!, $category: String!, $description: String!, $quantity: Float!, $value: Float!, $user: String!, $date_iso_string: String!) {
  updateSalesRecordById(
    id: $updateSalesRecordByIdId
    category: $category
    description: $description
    quantity: $quantity
    value: $value
    user: $user
    date_iso_string: $date_iso_string
  )
}
    `;
export type UpdateSalesRecordMutationFn = Apollo.MutationFunction<UpdateSalesRecordMutation, UpdateSalesRecordMutationVariables>;

/**
 * __useUpdateSalesRecordMutation__
 *
 * To run a mutation, you first call `useUpdateSalesRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSalesRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSalesRecordMutation, { data, loading, error }] = useUpdateSalesRecordMutation({
 *   variables: {
 *      updateSalesRecordByIdId: // value for 'updateSalesRecordByIdId'
 *      category: // value for 'category'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      value: // value for 'value'
 *      user: // value for 'user'
 *      date_iso_string: // value for 'date_iso_string'
 *   },
 * });
 */
export function useUpdateSalesRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalesRecordMutation, UpdateSalesRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalesRecordMutation, UpdateSalesRecordMutationVariables>(UpdateSalesRecordDocument, options);
      }
export type UpdateSalesRecordMutationHookResult = ReturnType<typeof useUpdateSalesRecordMutation>;
export type UpdateSalesRecordMutationResult = Apollo.MutationResult<UpdateSalesRecordMutation>;
export type UpdateSalesRecordMutationOptions = Apollo.BaseMutationOptions<UpdateSalesRecordMutation, UpdateSalesRecordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUserByIdId: String!, $firstName: String!, $lastName: String!, $email: String!, $phoneNumber: String!, $salary: Float!, $role: String!) {
  updateUserById(
    id: $updateUserByIdId
    first_name: $firstName
    last_name: $lastName
    email: $email
    phone_number: $phoneNumber
    salary: $salary
    role: $role
  )
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserByIdId: // value for 'updateUserByIdId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *      salary: // value for 'salary'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($updateUserPasswordId: String!, $currentPassword: String!, $newPassword: String!, $newPasswordConfirmation: String!) {
  updateUserPassword(
    id: $updateUserPasswordId
    current_password: $currentPassword
    new_password: $newPassword
    new_password_confirmation: $newPasswordConfirmation
  )
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      updateUserPasswordId: // value for 'updateUserPasswordId'
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirmation: // value for 'newPasswordConfirmation'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;