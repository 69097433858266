import * as yup from 'yup';

export const staffModalValidationSchema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid Email address')
    .required('This field is required'),

  password: yup.string().trim().required('This field is required'),

  phoneNumber: yup.string().required('This field is required'),

  salary: yup
    .string()
    .required('This field is required')
    .matches(
      //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      /^[0-9]{1,20}?$/,
      'Please enter a valid number'
    ),
});

export const updateStaffModalValidationSchema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid Email address')
    .required('This field is required'),

  phoneNumber: yup.string().required('This field is required'),

  salary: yup
    .string()
    .required('This field is required')
    .matches(
      //   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      /^[0-9]{1,20}?$/,
      'Please enter a valid number'
    ),
});

export const updateClientModalValidationSchema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid Email address')
    .required('This field is required'),

  phoneNumber: yup.string().required('This field is required'),
});
