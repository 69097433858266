// Packages
// import NumberFormat from 'react-number-format';
import { format, parseISO } from 'date-fns';

// Styles
import './Table.css';

// Icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const ClientTable = (props: any) => {
  return (
    <table>
      <thead>
        <tr>
          <th>S/N</th>
          <th>FIRST NAME</th>
          <th>LAST NAME</th>
          <th>EMAIL</th>
          <th>PHONE NUMBER</th>
          <th>DATE</th>
          <th colSpan={2} scope="colgroup">
            ACTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        {props.data
          ?.sort((a: any, b: any) =>
            a.createdAt! < b.createdAt!
              ? 1
              : b.createdAt! < a.createdAt!
              ? -1
              : 0
          )
          .slice(0, 10)
          .map((data: any, index: any) => {
            let serialNumber = index + 1;
            const formattedDate = format(
              parseISO(data.createdAt),
              'MMM do, yyy HH:mm a'
            );
            return (
              <tr key={data.id}>
                <td>{serialNumber}</td>
                <td>{data.first_name}</td>
                <td>{data.last_name}</td>
                <td>{data.email}</td>
                <td>{data.phone_number}</td>

                <td>{formattedDate}</td>
                <td className="action-icon edit-icon">
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.editRecord(data)}
                  >
                    <EditOutlinedIcon />
                  </span>
                </td>
                <td
                  className="action-icon delete-icon"
                  onClick={() => props.deleteRecord(data)}
                >
                  <span style={{ cursor: 'pointer' }}>
                    <DeleteOutlineOutlinedIcon />
                  </span>
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default ClientTable;
