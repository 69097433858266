// Define Action Types

export enum ActionType {
  ACTIVATE_OVERVIEW_STATE = 'ACTIVATE_OVERVIEW_STATE',
  ACTIVATE_EXPENSES_STATE = 'ACTIVATE_EXPENSES_STATE',
  ACTIVATE_SALES_STATE = 'ACTIVATE_SALES_STATE',
  ACTIVATE_CAPITAL_STATE = 'ACTIVATE_CAPITAL_STATE',
  ACTIVATE_TRANSACTIONS_STATE = 'ACTIVATE_TRANSACTIONS_STATE',
  ACTIVATE_ADMIN_STATE = 'ACTIVATE_ADMIN_STATE',
  ACTIVATE_CUSTOMERS_STATE = 'ACTIVATE_CUSTOMERS_STATE',
  ACTIVATE_SETTINGS_STATE = 'ACTIVATE_SETTINGS_STATE',
  ACTIVATE_STAFF_STATE = 'ACTIVATE_STAFF_STATE',
  ACTIVATE_CLIENT_STATE = 'ACTIVATE_CLIENT_STATE',
  ACTIVATE_CAPITAL_RECORDS_STATE = 'ACTIVATE_CAPITAL_RECORDS_STATE',
  ACTIVATE_SALES_RECORDS_STATE = 'ACTIVATE_SALES_RECORDS_STATE',
  ACTIVATE_ANIMAL_RECORDS_STATE = 'ACTIVATE_ANIMAL_RECORDS_STATE',
  ACTIVATE_DRUG_RECORDS_STATE = 'ACTIVATE_DRUG_RECORDS_STATE',
  ACTIVATE_FEED_RECORDS_STATE = 'ACTIVATE_FEED_RECORDS_STATE',
  ACTIVATE_MAINTENANCE_RECORDS_STATE = 'ACTIVATE_MAINTENANCE_RECORDS_STATE',
  ACTIVATE_MISCELLANEOUS_RECORDS_STATE = 'ACTIVATE_MISCELLANEOUS_RECORDS_STATE',
  ACTIVATE_SALARY_RECORDS_STATE = 'ACTIVATE_SALARY_RECORDS_STATE',
  ACTIVATE_LOGS_STATE = 'ACTIVATE_LOGS_STATE',
  ACTIVATE_DAILY_LOGS_STATE = 'ACTIVATE_DAILY_LOGS_STATE',
}

interface activateOverviewAction {
  type: ActionType.ACTIVATE_OVERVIEW_STATE;
}

interface activateStaffAction {
  type: ActionType.ACTIVATE_STAFF_STATE;
}

interface activateClientAction {
  type: ActionType.ACTIVATE_CLIENT_STATE;
}

interface activateCapitalRecordsAction {
  type: ActionType.ACTIVATE_CAPITAL_RECORDS_STATE;
}

interface activateSalesRecordsAction {
  type: ActionType.ACTIVATE_SALES_RECORDS_STATE;
}

interface activateAnimalRecordsAction {
  type: ActionType.ACTIVATE_ANIMAL_RECORDS_STATE;
}

interface activateDrugRecordsAction {
  type: ActionType.ACTIVATE_DRUG_RECORDS_STATE;
}

interface activateFeedRecordsAction {
  type: ActionType.ACTIVATE_FEED_RECORDS_STATE;
}

interface activateMaintenanceRecordsAction {
  type: ActionType.ACTIVATE_MAINTENANCE_RECORDS_STATE;
}

interface activateMiscellaneousRecordsAction {
  type: ActionType.ACTIVATE_MISCELLANEOUS_RECORDS_STATE;
}

interface activateSalaryRecordsAction {
  type: ActionType.ACTIVATE_SALARY_RECORDS_STATE;
}

interface activateExpensesAction {
  type: ActionType.ACTIVATE_EXPENSES_STATE;
}

interface activateSalesAction {
  type: ActionType.ACTIVATE_SALES_STATE;
}

interface activateCapitalAction {
  type: ActionType.ACTIVATE_CAPITAL_STATE;
}

interface activateTransactionsAction {
  type: ActionType.ACTIVATE_TRANSACTIONS_STATE;
}

interface activateAdminAction {
  type: ActionType.ACTIVATE_ADMIN_STATE;
}

interface activateCustomersAction {
  type: ActionType.ACTIVATE_CUSTOMERS_STATE;
}

interface activateSettingsAction {
  type: ActionType.ACTIVATE_SETTINGS_STATE;
}

interface activateLogsAction {
  type: ActionType.ACTIVATE_LOGS_STATE;
}

interface activateDailyLogsAction {
  type: ActionType.ACTIVATE_DAILY_LOGS_STATE;
}

export type Action =
  | activateOverviewAction
  | activateExpensesAction
  | activateSalesAction
  | activateCapitalAction
  | activateTransactionsAction
  | activateAdminAction
  | activateCustomersAction
  | activateSettingsAction
  | activateSalaryRecordsAction
  | activateMiscellaneousRecordsAction
  | activateMaintenanceRecordsAction
  | activateFeedRecordsAction
  | activateDrugRecordsAction
  | activateAnimalRecordsAction
  | activateCapitalRecordsAction
  | activateSalesRecordsAction
  | activateStaffAction
  | activateClientAction
  | activateLogsAction
  | activateDailyLogsAction;
