import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

// Graphql queries & mutation
import {
  useCreateSalesRecordMutation,
  useDeleteSalesRecordMutation,
  useUpdateSalesRecordMutation,
  useGetUsersQuery,
  GetUsersDocument,
  useGetSalesRecordsQuery,
} from '../../generated/graphql';

// // Styles
import '../Expenses/Expenses.css';

// // Components
import {
  ErrorAlert,
  SuccessAlert,
  Table,
  Modal,
  UpdateModal,
  DeleteModal,
} from '../../components';
import { Skeleton } from '@mui/material';

const CATEGORIES = ['Animals'];

const Sales = () => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData'));

  const [activeUserCard, setActiveUserCard] = useState<string>(userData?.id);
  const [salesAccBalance, setSalesAccBalance] = useState(0);

  const toggleActiveUserCard = (userId: string) => {
    setActiveUserCard(userId);
  };

  //   Queries and Mutations
  const { data, loading: loadingUsersData } = useGetUsersQuery({
    fetchPolicy: 'network-only',
  });
  const [createSalesRecord] = useCreateSalesRecordMutation();
  const [updateSalesRecordById] = useUpdateSalesRecordMutation();
  const [deleteSalesRecordById] = useDeleteSalesRecordMutation();
  const {
    data: salesData,
    loading: salesLoading,
    refetch,
  } = useGetSalesRecordsQuery({
    variables: {
      user: activeUserCard,
    },
    fetchPolicy: 'cache-and-network',
  });

  // State management
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<Boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<Boolean>(false);
  const [editableData, setEditableData] = useState<any>();
  const [startDate, setStartDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [loading, setLoading] = useState<Boolean>(false);
  const [isSuccessAlertActive, setIsSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isUpdateSuccessAlertActive, setIsUpdateSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isDeleteSuccessAlertActive, setIsDeleteSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isErrorAlertActive, setIsErrorAlertActive] =
    React.useState<Boolean>(false);
  const [isUpdateErrorAlertActive, setIsUpdateErrorAlertActive] =
    React.useState<Boolean>(false);
  const [isDeleteErrorAlertActive, setIsDeleteErrorAlertActive] =
    React.useState<Boolean>(false);

  const toggleModal = () => setIsOpen(!isOpen);
  const toggleUpdateModal = () => setIsUpdateModalOpen(!isUpdateModalOpen);
  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  // Create a record
  const submitForm = async (values: any, resetForm: any) => {
    setLoading(true);

    // Convert new Date() object to ISOString
    const isoDate = startDate.toISOString();

    let category: any = selectedOption;
    let description = values.description;
    let quantity = parseInt(values.quantity);
    let value = parseInt(values.value);
    let date_iso_string = isoDate;

    try {
      await createSalesRecord({
        variables: {
          category,
          description,
          quantity,
          value,
          user: activeUserCard,
          date_iso_string,
        },
        refetchQueries: [
          // { query: GetSalesRecordsDocument },
          { query: GetUsersDocument },
        ],
      });

      refetch({
        user: activeUserCard,
      });

      // Display Success alert
      setIsSuccessAlertActive(true);

      // Close Modal
      toggleModal();

      //Reset Form
      resetForm();
    } catch (error) {
      // Display Error alert
      setIsErrorAlertActive(true);
    } finally {
      // Reset loading state
      setLoading(false);

      // Update selected option state
      setSelectedOption('');
    }
  };

  // Update the record
  const editRecord = (values: any, resetForm: any) => {
    setLoading(true);

    // Convert new Date() object to ISOString
    const isoDate = startDate.toISOString();

    let updateSalesRecordByIdId = editableData!.id;
    let category = selectedOption;
    let description = values.description;
    let quantity = parseInt(values.quantity);
    let value = parseInt(values.value);
    let date_iso_string = isoDate;

    try {
      updateSalesRecordById({
        variables: {
          updateSalesRecordByIdId,
          category,
          description,
          quantity,
          value,
          user: activeUserCard,
          date_iso_string,
        },
        refetchQueries: [
          // { query: GetSalesRecordsDocument },
          { query: GetUsersDocument },
        ],
      });

      refetch({
        user: activeUserCard,
      });

      // Display Success alert
      setIsUpdateSuccessAlertActive(true);

      // Close Modal
      toggleUpdateModal();

      //Reset Form
      resetForm();
    } catch (error) {
      // Display error alert
      setIsUpdateErrorAlertActive(true);
    } finally {
      // Reset Loading state
      setLoading(false);

      // Update selected option state
      setSelectedOption('');
    }
  };
  // Delete a record
  const deleteRecord = () => {
    setLoading(true);

    let deleteSalesRecordByIdId = editableData!.id;

    try {
      deleteSalesRecordById({
        variables: {
          deleteSalesRecordByIdId,
          user: activeUserCard,
        },
        refetchQueries: [
          // { query: GetSalesRecordsDocument },
          { query: GetUsersDocument },
        ],
      });

      refetch({
        user: activeUserCard,
      });

      // Display success alert
      setIsDeleteSuccessAlertActive(true);

      // Close Modal
      toggleDeleteModal();
    } catch (error) {
      // Display error alert
      setIsDeleteErrorAlertActive(true);
    } finally {
      setLoading(false);
    }
  };

  // Get required values and display update modal
  const toggleUpdate = (record: any) => {
    setStartDate(new Date(record?.date_iso_string));
    setEditableData(record);

    setSelectedOption(record.category);

    toggleUpdateModal();
  };

  const toggleDelete = (record: any) => {
    setEditableData(record);

    toggleDeleteModal();
  };

  useEffect(() => {
    if (data) {
      const foundUser = data.users.find((user) => user.id === activeUserCard);

      if (foundUser) {
        const foundSalesAccount = foundUser.accounts.find(
          (account) => account.type === 'sales'
        );

        foundSalesAccount
          ? setSalesAccBalance(foundSalesAccount?.balance)
          : setSalesAccBalance(0);
      }
    }
  }, [activeUserCard, salesData, data]);

  return (
    <>
      <SuccessAlert
        isSuccessAlertActive={isSuccessAlertActive}
        setIsSuccessAlertActive={setIsSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your record has been added!"
      />
      <SuccessAlert
        isSuccessAlertActive={isUpdateSuccessAlertActive}
        setIsSuccessAlertActive={setIsUpdateSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your record has been updated!"
      />
      <SuccessAlert
        isSuccessAlertActive={isDeleteSuccessAlertActive}
        setIsSuccessAlertActive={setIsDeleteSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your record has been deleted!"
      />
      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not add record, please try again later!"
      />
      <ErrorAlert
        isErrorAlertActive={isUpdateErrorAlertActive}
        setIsErrorAlertActive={setIsUpdateErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not update record, please try again later!"
      />
      <ErrorAlert
        isErrorAlertActive={isDeleteErrorAlertActive}
        setIsErrorAlertActive={setIsDeleteErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not delete record, please try again later!"
      />

      <div className="expenses-don-variant-wrapper">
        {loadingUsersData ? (
          <div className="skeleton-ui-wrapper">
            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={70} />
            </div>

            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={70} />
            </div>

            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={300} />
            </div>
          </div>
        ) : (
          <>
            <div className="don-variant-user-wrapper">
              {data &&
                data.users
                  .sort((a, b) =>
                    a.role > b.role ? -1 : b.role > a.role ? 1 : 0
                  )
                  .map((user) => {
                    let userName =
                      user.first_name[user.first_name.length - 1] === 's'
                        ? `${user.first_name}' record`
                        : `${user.first_name}'s record`;

                    // console.log(activeUserCard, user?.id);

                    return (
                      <div
                        key={user?.id}
                        className={
                          activeUserCard === user?.id
                            ? 'active-expenses-don-user-card'
                            : 'inactive-expenses-don-user-card'
                        }
                        onClick={() => toggleActiveUserCard(user?.id)}
                      >
                        <h2>{userName}</h2>
                      </div>
                    );
                  })}
            </div>

            <div className="expenses-wrap">
              <div className="expenses-title-con">
                <h2 className="expenses-title">
                  Sales Account:&nbsp;&nbsp;
                  <NumberFormat
                    value={salesAccBalance}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'₦'}
                  />
                </h2>
              </div>
              <div className="add-record-con">
                <div className="first-con">
                  <h4 className="second-con-title">Recently added records</h4>
                  <button onClick={toggleModal}>Add a new record</button>
                </div>
                <div className="second-con">
                  {salesLoading ? (
                    <div style={{ display: 'flex' }}>
                      <Skeleton variant="rectangular" width={300} height={70} />

                      <Skeleton variant="rectangular" width={300} height={70} />

                      <Skeleton variant="rectangular" width={300} height={70} />
                    </div>
                  ) : (
                    <Table
                      data={salesData?.findAllSalesRecords}
                      editRecord={toggleUpdate}
                      deleteRecord={toggleDelete}
                    />
                  )}
                </div>
                {/* <h4 className="view-trans-link" onClick={toggleTransactionsState}>
                View all transactions
              </h4> */}
              </div>
            </div>
          </>
        )}
      </div>

      {isOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleModal}>
            X
          </div>
          <Modal
            submitForm={submitForm}
            startDate={startDate}
            setStartDate={setStartDate}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            loading={loading}
            CATEGORIES={CATEGORIES}
          />
        </div>
      )}
      {isUpdateModalOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleUpdateModal}>
            X
          </div>
          <UpdateModal
            submitForm={editRecord}
            startDate={startDate}
            setStartDate={setStartDate}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            editableData={editableData}
            loading={loading}
          />
        </div>
      )}
      {isDeleteModalOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleDeleteModal}>
            X
          </div>
          <DeleteModal
            loading={loading}
            deleteRecord={deleteRecord}
            toggleModal={toggleDeleteModal}
          />
        </div>
      )}
    </>
  );
};

export default Sales;
