import React, { useState } from 'react';

// Graphql queries & mutation
import {
  useGetClientQuery,
  useDeleteUserMutation,
  GetClientDocument,
  useUpdateUserMutation,
  useRegisterMutation,
} from '../../generated/graphql';

// Styles
import '../Expenses/Expenses.css';

// Components
import {
  ErrorAlert,
  SuccessAlert,
  UpdateClientModal,
  DeleteModal,
  ClientTable,
  RegisterClientModal,
} from '../../components';
import { Skeleton } from '@mui/material';

const Client = () => {
  // Queries and Mutations
  const { data, loading: clientLoading } = useGetClientQuery({
    fetchPolicy: 'network-only',
  });
  const [updateUserById] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [register] = useRegisterMutation();

  // State management
  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<Boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<Boolean>(false);
  const [editableData, setEditableData] = useState<any>();
  //   const [selectedOption, setSelectedOption] = useState<string>('');
  const [loading, setLoading] = useState<Boolean>(false);
  const [isSuccessAlertActive, setIsSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isUpdateSuccessAlertActive, setIsUpdateSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isDeleteSuccessAlertActive, setIsDeleteSuccessAlertActive] =
    React.useState<Boolean>(false);
  const [isErrorAlertActive, setIsErrorAlertActive] =
    React.useState<Boolean>(false);
  const [isUpdateErrorAlertActive, setIsUpdateErrorAlertActive] =
    React.useState<Boolean>(false);
  const [isDeleteErrorAlertActive, setIsDeleteErrorAlertActive] =
    React.useState<Boolean>(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const toggleUpdateModal = () => setIsUpdateModalOpen(!isUpdateModalOpen);

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  // Create a record
  const submitForm = async (values: any, resetForm: any) => {
    setLoading(true);

    // let category: any = selectedOption;
    let first_name = values.firstName;
    let last_name = values.lastName;
    let email = values.email;
    let password = values.password;
    let phoneNumber = values.phoneNumber;
    let role = values.role;
    let salary = parseInt(values.salary);

    try {
      await register({
        variables: {
          first_name,
          last_name,
          email,
          password,
          phoneNumber,
          salary,
          role,
        },
        refetchQueries: [{ query: GetClientDocument }],
      });

      // Display Success alert
      setIsSuccessAlertActive(true);

      // Close Modal
      toggleModal();

      //Reset Form
      resetForm();
    } catch (error) {
      // Display Error alert
      setIsErrorAlertActive(true);
    } finally {
      // Reset loading state
      setLoading(false);
    }
  };

  // Update the record
  const editRecord = (values: any, resetForm: any) => {
    setLoading(true);

    let updateUserByIdId = editableData!.id;
    let firstName = values.firstName;
    let lastName = values.lastName;
    let email = values.email;
    let phoneNumber = values.phoneNumber;
    let role = values.role;
    let salary = 0;

    try {
      updateUserById({
        variables: {
          updateUserByIdId,
          firstName,
          lastName,
          email,
          phoneNumber,
          salary,
          role,
        },
        refetchQueries: [{ query: GetClientDocument }],
      });

      // Display Success alert
      setIsUpdateSuccessAlertActive(true);

      // Close Modal
      toggleUpdateModal();

      //Reset Form
      resetForm();
    } catch (error) {
      // Display error alert
      setIsUpdateErrorAlertActive(true);
    } finally {
      // Reset Loading state
      setLoading(false);
    }
  };

  // Delete a record
  const deleteRecord = () => {
    setLoading(true);

    let deleteUserId = editableData!.id;

    try {
      deleteUser({
        variables: {
          deleteUserId,
        },
        refetchQueries: [{ query: GetClientDocument }],
      });

      // Display success alert
      setIsDeleteSuccessAlertActive(true);

      // Close Modal
      toggleDeleteModal();
    } catch (error) {
      // Display error alert
      setIsDeleteErrorAlertActive(true);
    } finally {
      setLoading(false);
    }
  };

  // Get required values and display update modal
  const toggleUpdate = (record: any) => {
    setEditableData(record);

    toggleUpdateModal();
  };

  const toggleDelete = (record: any) => {
    setEditableData(record);

    toggleDeleteModal();
  };

  return (
    <>
      <SuccessAlert
        isSuccessAlertActive={isSuccessAlertActive}
        setIsSuccessAlertActive={setIsSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your record has been added!"
      />
      <SuccessAlert
        isSuccessAlertActive={isUpdateSuccessAlertActive}
        setIsSuccessAlertActive={setIsUpdateSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your record has been updated!"
      />
      <SuccessAlert
        isSuccessAlertActive={isDeleteSuccessAlertActive}
        setIsSuccessAlertActive={setIsDeleteSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your record has been deleted!"
      />

      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not add record, please try again later!"
      />
      <ErrorAlert
        isErrorAlertActive={isUpdateErrorAlertActive}
        setIsErrorAlertActive={setIsUpdateErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not update record, please try again later!"
      />
      <ErrorAlert
        isErrorAlertActive={isDeleteErrorAlertActive}
        setIsErrorAlertActive={setIsDeleteErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not delete record, please try again later!"
      />

      <div className="expenses">
        <div className="expenses-wrap">
          <div className="add-record-con">
            <div className="first-con">
              <h4 className="second-con-title">Client records</h4>
              <button onClick={toggleModal}>Add a new Client</button>
            </div>
            <div className="second-con">
              {clientLoading ? (
                <div style={{ display: 'flex' }}>
                  <Skeleton variant="rectangular" width={300} height={70} />

                  <Skeleton variant="rectangular" width={300} height={70} />

                  <Skeleton variant="rectangular" width={300} height={70} />
                </div>
              ) : (
                <ClientTable
                  data={data?.findAllClient}
                  editRecord={toggleUpdate}
                  deleteRecord={toggleDelete}
                />
              )}
            </div>
            {/* <h4 className="view-trans-link" onClick={toggleTransactionsState}>
              View all transactions
            </h4> */}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleModal}>
            X
          </div>
          <RegisterClientModal submitForm={submitForm} loading={loading} />
        </div>
      )}
      {isUpdateModalOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleUpdateModal}>
            X
          </div>
          <UpdateClientModal
            submitForm={editRecord}
            editableData={editableData}
            loading={loading}
          />
        </div>
      )}
      {isDeleteModalOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleDeleteModal}>
            X
          </div>
          <DeleteModal
            loading={loading}
            deleteRecord={deleteRecord}
            toggleModal={toggleDeleteModal}
          />
        </div>
      )}
    </>
  );
};

export default Client;
