import { useState } from 'react';

// Styles
import './Transactions.css';

// Queries
import {
  useGetExpenseRecordsQuery,
  // useGetSalesRecordsQuery,
  useGetCapitalRecordsQuery,
} from '../../generated/graphql';

import TransactionTable from '../../components/Table/TransactionsTable/TransactionTable';
import CapitalTransactionTable from '../../components/Table/TransactionsTable/CapitalTransactionTable';

const Transactions = () => {
  const userObj: any = 'yes';
  const { data: expensesData } = useGetExpenseRecordsQuery(userObj);
  // const { data: salesData } = useGetSalesRecordsQuery(userObj);
  const { data: capitalData } = useGetCapitalRecordsQuery(userObj);

  const [activeTab, setActiveTab] = useState({
    expenseTab: true,
    salesTab: false,
    capitalTab: false,
  });

  const activateExpTab = () =>
    setActiveTab({
      expenseTab: true,
      salesTab: false,
      capitalTab: false,
    });

  const activateSalTab = () =>
    setActiveTab({
      expenseTab: false,
      salesTab: true,
      capitalTab: false,
    });

  const activateCapTab = () =>
    setActiveTab({
      expenseTab: false,
      salesTab: false,
      capitalTab: true,
    });

  return (
    <div className="transactions">
      <div className="transactions-wrap">
        <div className="main-trans-con">
          <div className="trans-header-tabs-con">
            <div
              onClick={activateExpTab}
              className={
                activeTab.expenseTab
                  ? 'trans-tab active-trans-tab'
                  : 'trans-tab inactive-trans-tab'
              }
            >
              Expense records
            </div>
            <div
              onClick={activateSalTab}
              className={
                activeTab.salesTab
                  ? 'trans-tab active-trans-tab'
                  : 'trans-tab inactive-trans-tab'
              }
            >
              Sales records
            </div>
            <div
              onClick={activateCapTab}
              className={
                activeTab.capitalTab
                  ? 'trans-tab active-trans-tab'
                  : 'trans-tab inactive-trans-tab'
              }
            >
              Capital records
            </div>
          </div>
          <div className="trans-body-con">
            {activeTab.expenseTab && (
              <TransactionTable data={expensesData?.findAllExpenseRecords} />
            )}
            {activeTab.salesTab && (
              // <TransactionTable data={salesData?.findAllSalesRecords} />
              <></>
            )}
            {activeTab.capitalTab && (
              <CapitalTransactionTable
                data={capitalData?.findAllCapitalRecords}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
