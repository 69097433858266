// Styles
import './Modal.css';
import './DeleteButton.css';

// Loading spinner
import { CircularProgress } from '@mui/material';

const DeleteModal = (props: any) => {
  return (
    <div className="delete-modal-body">
      <span>Are you sure you want to delete this record ?</span>
      <div className="btn-con">
        <button className="del-btn" onClick={props.deleteRecord}>
          {props.loading ? (
            <CircularProgress style={{ color: '#fff' }} size={25} />
          ) : (
            'Delete'
          )}
        </button>
        <button onClick={props.toggleModal}>Cancel</button>
      </div>
    </div>
  );
};

export default DeleteModal;
