// Styles
import './Table.css';

// Icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ILog } from '../../types/log';

interface IProps {
  data: ILog[];
  editRecord: (record: ILog) => void;
  deleteRecord: (record: ILog) => void;
  activePage: number;
  rowsPerPage: number;
}

const LogsTable = (props: IProps) => {
  let serialNumberEdge = props.rowsPerPage * (props.activePage - 1) + 1;

  return (
    <table>
      <thead>
        <tr>
          <th>S/N</th>
          <th>DATE</th>
          <th>DETAILS</th>
          <th colSpan={2} scope="colgroup">
            ACTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((data: ILog, index: number) => {
          let serialNumber = index + 1;

          return (
            <tr key={data.id}>
              <td>
                {props.activePage === 1
                  ? serialNumber
                  : serialNumberEdge + index}
              </td>
              <td>{data.date}</td>
              <td>{data.description}</td>
              <td className="action-icon edit-icon">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.editRecord(data)}
                >
                  <EditOutlinedIcon />
                </span>
              </td>
              <td
                className="action-icon delete-icon"
                onClick={() => props.deleteRecord(data)}
              >
                <span style={{ cursor: 'pointer' }}>
                  <DeleteOutlineOutlinedIcon />
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LogsTable;
