// Packages
import NumberFormat from 'react-number-format';
import { format, parseISO } from 'date-fns';

// Styles
import './Table.css';

// Icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const Table = (props: any) => {
  return (
    <table>
      <thead>
        <tr>
          <th>S/N</th>
          <th>CATEGORIES</th>
          <th>DESCRIPTION</th>
          <th>QTY</th>
          <th>VALUE (₦)</th>
          <th>TOTAL (₦)</th>
          <th>DATE</th>
          <th colSpan={2} scope="colgroup">
            ACTIONS
          </th>
        </tr>
      </thead>
      <tbody>
        {props.data?.slice(0, 10).map((data: any, index: any) => {
          let serialNumber = index + 1;
          const formattedDate = format(
            parseISO(data?.date_iso_string),
            'do MMM, yyy'
          );
          return (
            <tr key={data.id}>
              <td>{serialNumber}</td>
              <td>{data.category}</td>
              <td>{data.description}</td>
              <td>{data.quantity}</td>
              <td>
                <NumberFormat
                  value={data.value}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={''}
                />
              </td>
              <td>
                <NumberFormat
                  value={data.total_amount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={''}
                />
              </td>
              <td>{formattedDate}</td>
              <td className="action-icon edit-icon">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.editRecord(data)}
                >
                  <EditOutlinedIcon />
                </span>
              </td>
              <td
                className="action-icon delete-icon"
                onClick={() => props.deleteRecord(data)}
              >
                <span style={{ cursor: 'pointer' }}>
                  <DeleteOutlineOutlinedIcon />
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
