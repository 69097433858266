// @ts-ignore
// @ts-nocheck

import { useState } from 'react';

// Styles
import { useHistory } from 'react-router-dom';
import './Sidebar.css';

// Images
import brandLogo from '../../assets/SMV_logo_white.png';

// Icons
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

// Action Creators
import {
  // activateCustomersState,
  activateExpensesState,
  activateCapitalState,
  activateOverviewState,
  activateSalesState,
  // activateStaffState,
  activateClientState,
  activateSettingsState,
  activateCapitalRecordsState,
  activateAdminState,
  activateSalesRecordsState,
  activateAnimalRecordsState,
  activateFeedRecordsState,
  activateDrugRecordsState,
  activateMaintenanceRecordsState,
  activateMiscellaneousRecordsState,
  activateSalaryRecordsState,
  activateLogsState,
  activateDailyLogsState,
} from '../../app/features/actionCreators/appStateActionCreators';

// Hooks
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../app/hooks';
import { setAccessToken } from '../../utils/accessToken';
import { useLogoutMutation } from '../../generated/graphql';

const Sidebar = () => {
  const [isTransMenuOpen, setIsTransMenuOpen] = useState(false);
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const getUser: any = () => {
    return JSON.parse(localStorage.getItem('userData'));
  };

  let user = getUser();

  const navToHome = () => {
    history.push('/');
  };

  const [logout] = useLogoutMutation();

  // App state
  const {
    overview,
    expenses,
    sales,
    // staff,
    client,
    capitalRecords,
    salesRecords,
    animalRecords,
    feedRecords,
    drugRecords,
    maintenanceRecords,
    miscellaneousRecords,
    salaryRecords,
    capital,
    admin,
    logs,
    dailyLogs,
    settings,
  } = useTypedSelector((state) => state.appState);

  const toggleTransMenu = () => setIsTransMenuOpen(!isTransMenuOpen);

  const toggleAdminMenu = () => setIsAdminMenuOpen(!isAdminMenuOpen);

  // Toggle Overview view state
  const toggleOverviewState = async () => {
    await dispatch(activateOverviewState());
  };

  // Toggle Expenses view state
  const toggleExpensesState = async () => {
    await dispatch(activateExpensesState());
  };

  // Toggle Sales view state
  const toggleSalesState = async () => {
    await dispatch(activateSalesState());
  };

  // Toggle Expenses view state
  const toggleCapitalState = async () => {
    await dispatch(activateCapitalState());
  };

  // Toggle Transactions view state
  const toggleCapitalRecordsState = async () => {
    await dispatch(activateCapitalRecordsState());
  };

  const toggleSalesRecordsState = async () => {
    await dispatch(activateSalesRecordsState());
  };

  const toggleAnimalRecordsState = async () => {
    await dispatch(activateAnimalRecordsState());
  };

  const toggleFeedRecordsState = async () => {
    await dispatch(activateFeedRecordsState());
  };

  const toggleDrugRecordsState = async () => {
    await dispatch(activateDrugRecordsState());
  };

  const toggleMaintenanceRecordsState = async () => {
    await dispatch(activateMaintenanceRecordsState());
  };

  const toggleMiscellaneousRecordsState = async () => {
    await dispatch(activateMiscellaneousRecordsState());
  };

  const toggleSalaryRecordsState = async () => {
    await dispatch(activateSalaryRecordsState());
  };

  // const toggleStaffState = async () => {
  //   await dispatch(activateStaffState());
  // };

  const toggleClientState = async () => {
    await dispatch(activateClientState());
  };

  // Toggle Admin view state
  const toggleAdminState = async () => {
    await dispatch(activateAdminState());
  };

  // Toggle Logs view state
  const toggleLogsState = async () => {
    await dispatch(activateLogsState());
  };

  // Toggle DailyLogs view state
  const toggleDailyLogsState = async () => {
    await dispatch(activateDailyLogsState());
  };

  // Toggle Customers view state
  // const toggleCustomersState = async () => {
  //   await dispatch(activateCustomersState());
  // };

  // Toggle Settings view state
  const toggleSettingsState = async () => {
    await dispatch(activateSettingsState());
  };

  const logoutUser: any = async () => {
    try {
      await logout();

      localStorage.removeItem('userData');
    } catch {}
    setAccessToken('');
    // await client.resetStore();
    history.replace('/login');

    toggleOverviewState();
  };

  return (
    <div className="sidebar">
      <header className="sidebar-header">
        <div className="sidebar-logo" onClick={navToHome}>
          <img src={brandLogo} alt="Brand Logo" />
        </div>
      </header>

      <div className="sidebar-menu">
        <div className="sidebar-menu-wrapper">
          <div className="sidebar-menu-one">
            <span
              className={overview ? 'menu-link active-link' : 'menu-link'}
              onClick={toggleOverviewState}
            >
              <span className="menu-icon" aria-hidden="true">
                <HomeOutlinedIcon />
              </span>
              Overview
            </span>
            {user?.role !== 'client' && (
              <>
                <span
                  className={capital ? 'menu-link active-link' : 'menu-link'}
                  onClick={toggleCapitalState}
                >
                  <span className="menu-icon" aria-hidden="true">
                    <AccountBalanceOutlinedIcon />
                  </span>
                  Capital
                </span>
                <span
                  className={expenses ? 'menu-link active-link' : 'menu-link'}
                  onClick={toggleExpensesState}
                >
                  <span className="menu-icon" aria-hidden="true">
                    <PaymentOutlinedIcon />
                  </span>
                  Expenses
                </span>
                <span
                  className={sales ? 'menu-link active-link' : 'menu-link'}
                  onClick={toggleSalesState}
                >
                  <span className="menu-icon" aria-hidden="true">
                    <AccountBalanceWalletOutlinedIcon />
                  </span>
                  Sales
                </span>
              </>
            )}
            <span className="menu-link" onClick={toggleTransMenu}>
              <span className="menu-icon" aria-hidden="true">
                <ReceiptOutlinedIcon />
              </span>
              Transactions{' '}
              {isTransMenuOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </span>
            {isTransMenuOpen && (
              <div className="sub-menu">
                <span
                  className={
                    capitalRecords
                      ? 'sub-menu-link sub-active-link'
                      : 'sub-menu-link'
                  }
                  onClick={toggleCapitalRecordsState}
                >
                  Capital records
                </span>
                <span
                  className={
                    salesRecords
                      ? 'sub-menu-link sub-active-link'
                      : 'sub-menu-link'
                  }
                  onClick={toggleSalesRecordsState}
                >
                  Sales records
                </span>
                <span
                  className={
                    animalRecords
                      ? 'sub-menu-link sub-active-link'
                      : 'sub-menu-link'
                  }
                  onClick={toggleAnimalRecordsState}
                >
                  Animal records
                </span>
                <span
                  className={
                    drugRecords
                      ? 'sub-menu-link sub-active-link'
                      : 'sub-menu-link'
                  }
                  onClick={toggleDrugRecordsState}
                >
                  Drug records
                </span>
                <span
                  className={
                    feedRecords
                      ? 'sub-menu-link sub-active-link'
                      : 'sub-menu-link'
                  }
                  onClick={toggleFeedRecordsState}
                >
                  Feed records
                </span>
                <span
                  className={
                    maintenanceRecords
                      ? 'sub-menu-link sub-active-link'
                      : 'sub-menu-link'
                  }
                  onClick={toggleMaintenanceRecordsState}
                >
                  Maintenance records
                </span>
                <span
                  className={
                    miscellaneousRecords
                      ? 'sub-menu-link sub-active-link'
                      : 'sub-menu-link'
                  }
                  onClick={toggleMiscellaneousRecordsState}
                >
                  Miscellaneous records
                </span>
                <span
                  className={
                    salaryRecords
                      ? 'sub-menu-link sub-active-link'
                      : 'sub-menu-link'
                  }
                  onClick={toggleSalaryRecordsState}
                >
                  Salary records
                </span>
              </div>
            )}

            {user?.role === 'don' && (
              <>
                <span className="menu-link" onClick={toggleAdminMenu}>
                  <span className="menu-icon" aria-hidden="true">
                    <PeopleAltOutlinedIcon />
                  </span>
                  Admin{' '}
                  {isAdminMenuOpen ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </span>
                {isAdminMenuOpen && (
                  <div className="sub-menu">
                    <span
                      className={
                        admin
                          ? 'sub-menu-link sub-active-link'
                          : 'sub-menu-link'
                      }
                      onClick={toggleAdminState}
                    >
                      Accounts
                    </span>
                    {/* <span
                      className={
                        staff
                          ? 'sub-menu-link sub-active-link'
                          : 'sub-menu-link'
                      }
                      onClick={toggleStaffState}
                    >
                      Staff
                    </span> */}
                    <span
                      className={
                        client
                          ? 'sub-menu-link sub-active-link'
                          : 'sub-menu-link'
                      }
                      onClick={toggleClientState}
                    >
                      Client
                    </span>
                    <span
                      className={
                        dailyLogs
                          ? 'sub-menu-link sub-active-link'
                          : 'sub-menu-link'
                      }
                      onClick={toggleDailyLogsState}
                    >
                      Daily Logs
                    </span>
                  </div>
                )}
              </>
            )}

            {/* <span
              className={customers ? 'menu-link active-link' : 'menu-link'}
              onClick={toggleCustomersState}
            >
              <span className="menu-icon" aria-hidden="true">
                <PeopleAltOutlinedIcon />
              </span>
              Customers
            </span> */}

            <span
              className={logs ? 'menu-link active-link' : 'menu-link'}
              onClick={toggleLogsState}
            >
              <span className="menu-icon" aria-hidden="true">
                <AssessmentIcon />
              </span>
              Logs
            </span>

            <span
              className={settings ? 'menu-link active-link' : 'menu-link'}
              onClick={toggleSettingsState}
            >
              <span className="menu-icon" aria-hidden="true">
                <SettingsOutlinedIcon />
              </span>
              Settings
            </span>
          </div>
          <div>
            <span className={'menu-link logout-icon'} onClick={logoutUser}>
              <span className="menu-icon" aria-hidden="true">
                <LogoutIcon />
              </span>
              Logout
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
