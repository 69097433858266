import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

// Styles
import '../Transactions/Transactions.css';
import './Admin.css';

// Queries
import {
  GetAccountsDocument,
  GetProfitMarginDocument,
  GetUsersDocument,
  useGetAccountsQuery,
  useGetUsersQuery,
  useResetAccountsMutationMutation,
} from '../../generated/graphql';
import { useGetProfitMarginQuery } from '../../generated/graphql';
import { CircularProgress, Skeleton } from '@mui/material';
import { ErrorAlert, SuccessAlert } from '../../components';

interface IAccountBalance {
  capitalBalance: number;
  expenseBalance: number;
  salesBalance: number;
}

const Admin = () => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData'));

  // Queries and Mutations
  const { data, loading: loadingUsersData } = useGetUsersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [resetAccounts] = useResetAccountsMutationMutation();

  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const [loading, setLoading] = useState<Boolean>(false);
  const [isSuccessAlertActive, setIsSuccessAlertActive] =
    useState<Boolean>(false);
  const [isErrorAlertActive, setIsErrorAlertActive] = useState<Boolean>(false);
  const [activeUserCard, setActiveUserCard] = useState<string>(userData?.id);
  const [userAccountState, setUserAccountState] = useState<IAccountBalance>({
    capitalBalance: 0,
    expenseBalance: 0,
    salesBalance: 0,
  });
  const [capitalAccountState, setCapitalAccountState] = useState<number>(0);
  const [expenseAccountState, setExpenseAccountState] = useState<number>(0);
  const [salesAccountState, setSalesAccountState] = useState<number>(0);

  const toggleActiveUserCard = (userId: string) => {
    setActiveUserCard(userId);
  };

  const toggleModal = () => setIsOpen(!isOpen);

  const {
    data: getProfitMarginData,
    loading: getProfitLoading,
    refetch,
  } = useGetProfitMarginQuery({
    variables: {
      user: activeUserCard,
    },
    fetchPolicy: 'network-only',
  });

  const resetAccount = () => {
    setLoading(true);

    try {
      resetAccounts({
        variables: {
          user: activeUserCard,
        },
        refetchQueries: [{ query: GetUsersDocument }],
      });

      refetch({
        user: activeUserCard,
      });

      // Display success alert
      setIsSuccessAlertActive(true);

      // Close Modal
      toggleModal();
    } catch (error) {
      // Display error alert
      setIsErrorAlertActive(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      const foundUser = data.users.find((user) => user.id === activeUserCard);

      if (foundUser) {
        foundUser.accounts.forEach((account) => {
          account.type === 'capital' && setCapitalAccountState(account.balance);
          account.type === 'expenses' &&
            setExpenseAccountState(account.balance);
          account.type === 'sales' && setSalesAccountState(account.balance);
        });
      }
    }
  }, [activeUserCard, userAccountState, data]);

  return (
    <>
      <SuccessAlert
        isSuccessAlertActive={isSuccessAlertActive}
        setIsSuccessAlertActive={setIsSuccessAlertActive}
        severity="success"
        variant="filled"
        duration={6000}
        title="Successful request"
        message="Your accounts have been reset!"
      />

      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Request failed"
        message="Could not reset accounts, please try again later!"
      />

      <div className="admin">
        {loadingUsersData ? (
          <div className="skeleton-ui-wrapper">
            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={70} />
            </div>

            <div className="skeleton-ui">
              <Skeleton variant="rectangular" width={900} height={400} />
            </div>
          </div>
        ) : (
          <>
            <div className="don-variant-user-wrapper">
              {data &&
                data.users
                  .sort((a, b) =>
                    a.role > b.role ? -1 : b.role > a.role ? 1 : 0
                  )
                  .map((user) => {
                    let userName =
                      user.first_name[user.first_name.length - 1] === 's'
                        ? `${user.first_name}' record`
                        : `${user.first_name}'s record`;

                    return (
                      <div
                        key={user?.id}
                        className={
                          activeUserCard === user?.id
                            ? 'active-expenses-don-user-card'
                            : 'inactive-expenses-don-user-card'
                        }
                        onClick={() => toggleActiveUserCard(user?.id)}
                      >
                        <h2>{userName}</h2>
                      </div>
                    );
                  })}
            </div>

            <div className="admin-con-wrapper">
              {getProfitLoading ? (
                <>
                  <div style={{ display: 'flex', gap: '1em' }}>
                    <Skeleton variant="rectangular" width={250} height={400} />

                    <Skeleton variant="rectangular" width={250} height={400} />
                  </div>
                </>
              ) : (
                <>
                  <div className="first-admin-con">
                    <div className="capital-acc-card admin-margin">
                      <h3 className="acc-title">Capital account</h3>
                      <h3 className="acct-balance">
                        <NumberFormat
                          value={capitalAccountState}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'₦'}
                        />
                      </h3>
                    </div>

                    <div className="expenses-acc-card admin-margin">
                      <h3 className="acc-title">Expenses account</h3>
                      <h3 className="acct-balance">
                        <NumberFormat
                          value={expenseAccountState}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'₦'}
                        />
                      </h3>
                    </div>

                    <div className="sales-acc-card admin-margin">
                      <h3 className="acc-title">Sales account</h3>
                      <h3 className="acct-balance">
                        <NumberFormat
                          value={salesAccountState}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'₦'}
                        />
                      </h3>
                    </div>
                  </div>
                  <div className="second-admin-con">
                    <div className="profit-acc-card">
                      <h3 className="acc-title">Profit/Loss Margin</h3>
                      <h3
                        className={
                          getProfitMarginData!.getProfitMargin < 0
                            ? 'acct-balance red-color'
                            : getProfitMarginData!.getProfitMargin > 0
                            ? 'acct-balance green-color'
                            : 'acct-balance'
                          // 'acct-balance'
                        }
                      >
                        <NumberFormat
                          value={getProfitMarginData?.getProfitMargin}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'₦'}
                        />
                      </h3>
                    </div>
                    <div className="reset-acc-card">
                      <h3 className="acc-title">Reset Account Values</h3>
                      <button className="admin-reset-btn" onClick={toggleModal}>
                        Reset accounts
                      </button>
                    </div>
                  </div>
                  {/* </div> */}
                </>
              )}
            </div>
          </>
        )}
      </div>

      {isOpen && (
        <div className="modal-bg">
          <div className="close-btn" onClick={toggleModal}>
            X
          </div>
          <div className="delete-modal-body">
            <span>Are you sure you want to reset the accounts?</span>
            <div className="btn-con">
              <button className="del-btn" onClick={resetAccount}>
                {loading ? (
                  <CircularProgress style={{ color: '#fff' }} size={25} />
                ) : (
                  'Reset'
                )}
              </button>
              <button onClick={toggleModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Admin;
