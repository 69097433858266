// Styles
import '../Transactions/Transactions.css';

import ClientMiscellaneousRecords from './ClientMiscellaneousRecords';
import DonMiscellaneousRecords from './DonMiscellaneousRecords';

const MiscellaneousRecords = () => {
  // @ts-ignore
  const userData = JSON.parse(localStorage.getItem('userData'));

  if (userData?.role === 'don') {
    return (
      <>
        <DonMiscellaneousRecords userData={userData} />
      </>
    );
  } else {
    return (
      <>
        <ClientMiscellaneousRecords userData={userData} />
      </>
    );
  }
};

export default MiscellaneousRecords;
