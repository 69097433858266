import { Action, ActionType } from '../actionTypes/getUserActionTypes';
import { UserState } from '../../../types/user';

const initialState = {
  userData: {},
};

const appStateReducer = (
  state: any = initialState,
  action: Action
): UserState => {
  switch (action.type) {
    case ActionType.GET_USER:
      return {
        userData: action.payload,
      };

    default:
      return state;
  }
};

export default appStateReducer;
