import React, { useState } from 'react';
import { RouteComponentProps, Redirect, Link } from 'react-router-dom';
import { Formik } from 'formik';
import { CircularProgress } from '@mui/material';

// Styles
import './Login.css';
import '../../constants/theme.css';

// Images
import brandLogo from '../../assets/SMV_logo_white.png';

// Utils
import { setAccessToken, getAccessToken } from '../../utils/accessToken';

// Mutations
import { useLoginMutation } from '../../generated/graphql';

// Validation schema
import { loginValidationSchema } from './validationSchema';

// Components
import { ErrorAlert } from '../../components';
import InputComponent from '../../components/InputComponent';

interface FormValues {
  email: string;
  password: string;
}

interface UserData {
  id: string;
  first_name: string;
  last_name: string;
  role: string;
}

const Login: React.FC<RouteComponentProps> = ({ history }) => {
  const accessToken = getAccessToken();

  if (accessToken) {
    <Redirect to="/dashboard" />;
  }

  var userData: UserData = {
    id: '',
    first_name: '',
    last_name: '',
    role: '',
  };

  // const [loading, setLoading] = useState<boolean>(false);
  const [isErrorAlertActive, setIsErrorAlertActive] = useState<boolean>(false);
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(false);
  const [login, { loading, error }] = useLoginMutation();

  const togglePasswordVisibility = () => setIsPasswordHidden(!isPasswordHidden);

  // console.log('graphql error >', error);

  const submitForm = async (values: FormValues) => {
    // setLoading(true);

    let email = values.email.toLowerCase();
    let password = values.password;

    console.log({
      email,
      password,
    });

    try {
      const response: any = await login({
        variables: {
          email,
          password,
        },
      });

      if (response && response.data) {
        setAccessToken(response.data.login.access_token);

        userData.id = response.data.login.user.id;
        userData.first_name = response.data.login.user.first_name;
        userData.last_name = response.data.login.user.last_name;
        userData.role = response.data.login.user.role;

        localStorage.setItem('userData', JSON.stringify(userData));

        history.replace('/dashboard');
      }
    } catch (error) {
      console.log('request error!');
      setIsErrorAlertActive(true);
    } finally {
      // setLoading(false);
    }
  };

  const navToHome = () => {
    history.replace('/');
  };

  return (
    <div className="login">
      <ErrorAlert
        isErrorAlertActive={isErrorAlertActive}
        setIsErrorAlertActive={setIsErrorAlertActive}
        severity="error"
        variant="filled"
        duration={6000}
        title="Could not login to your account"
        message={
          error
            ? error.graphQLErrors
              ? error.graphQLErrors[0]?.message
              : 'Invalid username or password'
            : 'Check your internet connection and try again'
        }
      />
      <div className="form-con">
        <div className="app-logo" onClick={navToHome}>
          <img src={brandLogo} alt="Brand Logo" />
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validateOnMount={true}
          validationSchema={loginValidationSchema}
          onSubmit={(values) => submitForm(values)}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              <h2>LOGIN</h2>

              <InputComponent
                type="email"
                name="email"
                placeholder="Email"
                onChange={props.handleChange('email')}
                onBlur={props.handleBlur('email')}
                value={props.values.email.trim()}
              />
              {props.errors.email && props.touched.email && (
                <p className="errors">{props.errors.email}</p>
              )}

              <InputComponent
                type="password"
                name="password"
                placeholder="Password"
                onChange={props.handleChange('password')}
                onBlur={props.handleBlur('password')}
                value={props.values.password.trim()}
                isPasswordHidden={isPasswordHidden}
                togglePasswordVisibility={togglePasswordVisibility}
              />

              {props.errors.password && props.touched.password && (
                <p className="errors">{props.errors.password}</p>
              )}

              <button type="submit" disabled={loading!}>
                {loading ? (
                  <CircularProgress style={{ color: '#fff' }} size={25} />
                ) : (
                  'Login to your account'
                )}
              </button>
            </form>
          )}
        </Formik>
        <Link
          to="/forgot-password"
          style={{
            textDecoration: 'none',
            color: '#fff',
            marginTop: '1em',
          }}
        >
          <span className="forgot-pwd-txt curs-point">
            Forgot your password?
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Login;
