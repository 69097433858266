import { Action, ActionType } from '../actionTypes/appStateActionTypes';
import { AppState } from '../../../types/appState';

const initialState = {
  overview: true,
  expenses: false,
  sales: false,
  capital: false,
  transactions: false,
  admin: false,
  staff: false,
  client: false,
  customers: false,
  settings: false,
  capitalRecords: false,
  salesRecords: false,
  animalRecords: false,
  drugRecords: false,
  feedRecords: false,
  maintenanceRecords: false,
  miscellaneousRecords: false,
  salaryRecords: false,
  logs: false,
  dailyLogs: false,
  stateName: 'Overview',
};

const appStateReducer = (
  state: AppState = initialState,
  action: Action
): AppState => {
  switch (action.type) {
    case ActionType.ACTIVATE_OVERVIEW_STATE:
      return {
        overview: true,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Overview',
      };

    case ActionType.ACTIVATE_EXPENSES_STATE:
      return {
        overview: false,
        expenses: true,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Expenses',
      };

    case ActionType.ACTIVATE_SALES_STATE:
      return {
        overview: false,
        expenses: false,
        sales: true,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Sales',
      };

    case ActionType.ACTIVATE_CAPITAL_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: true,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Capital',
      };

    case ActionType.ACTIVATE_TRANSACTIONS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: true,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_ADMIN_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: true,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Admin',
      };

    case ActionType.ACTIVATE_CUSTOMERS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: true,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Customers',
      };

    case ActionType.ACTIVATE_SETTINGS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: true,
        logs: false,
        dailyLogs: false,
        stateName: 'Settings',
      };

    case ActionType.ACTIVATE_STAFF_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: true,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Admin',
      };

    case ActionType.ACTIVATE_CLIENT_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: true,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Admin',
      };

    case ActionType.ACTIVATE_DAILY_LOGS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: true,
        stateName: 'Admin',
      };

    case ActionType.ACTIVATE_CAPITAL_RECORDS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: true,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_SALES_RECORDS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: true,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_ANIMAL_RECORDS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: true,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_DRUG_RECORDS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: true,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_FEED_RECORDS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: true,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_MAINTENANCE_RECORDS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: true,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_MISCELLANEOUS_RECORDS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: true,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_SALARY_RECORDS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: true,
        customers: false,
        settings: false,
        logs: false,
        dailyLogs: false,
        stateName: 'Transactions',
      };

    case ActionType.ACTIVATE_LOGS_STATE:
      return {
        overview: false,
        expenses: false,
        sales: false,
        capital: false,
        transactions: false,
        admin: false,
        staff: false,
        client: false,
        capitalRecords: false,
        salesRecords: false,
        animalRecords: false,
        drugRecords: false,
        feedRecords: false,
        maintenanceRecords: false,
        miscellaneousRecords: false,
        salaryRecords: false,
        customers: false,
        settings: false,
        logs: true,
        dailyLogs: false,
        stateName: 'Logs',
      };

    default:
      return state;
  }
};

export default appStateReducer;
