// Styles
import '../Transactions/Transactions.css';

// Queries
import { useGetCapitalRecordsQuery } from '../../generated/graphql';

// import TransactionTable from '../../components/Table/TransactionsTable/TransactionTable';
import CapitalTransactionTable from '../../components/Table/TransactionsTable/CapitalTransactionTable';
import { Skeleton } from '@mui/material';

const ClientCapitalRecords = (props: any) => {
  const { userData } = props;
  const userId = userData.id;

  const { data: capitalData, loading: capitalLoading } =
    useGetCapitalRecordsQuery({
      variables: {
        user: userId,
      },
      fetchPolicy: 'cache-and-network',
    });

  return (
    <div className="transactions">
      <div className="transactions-wrap">
        <div className="main-trans-con">
          <div className="trans-header-tabs-con">
            <div className="trans-tab active-trans-tab">Capital records</div>
          </div>
          <div className="trans-body-con">
            {/* {activeTab.salesTab && (
              <TransactionTable data={salesData?.findAllSalesRecords} />
            )} */}
            {capitalLoading ? (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Skeleton variant="rectangular" width={300} height={70} />

                <Skeleton variant="rectangular" width={300} height={70} />

                <Skeleton variant="rectangular" width={300} height={70} />
              </div>
            ) : (
              <CapitalTransactionTable
                data={capitalData?.findAllCapitalRecords}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCapitalRecords;
