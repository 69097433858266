// import axios from 'axios';
import React from 'react';
import { setAccessToken } from './utils/accessToken';
import Routes from './routes/Routes';
import { config } from './config';

function App() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetch(`${config.BASE_URL}refresh_token`, {
      method: 'POST',
      credentials: 'include',
    })
      .then(async (x) => {
        const accessToken = await x.json();

        setAccessToken(accessToken.access_token);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  if (loading) {
    return <div></div>;
  }

  return <Routes />;
}

export default App;
